import React, { useState, useEffect, useRef } from "react";
import logo from "../wt.png";
import pic from "./assets/ww.gif";
import Banner from "./Banner";
import Footer from "./Footer";
import MessageBox from "./MessageBox";
import ThumbUpTwoToneIcon from "@mui/icons-material/ThumbUpTwoTone";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ShareTwoToneIcon from "@mui/icons-material/ShareTwoTone";
import CancelIcon from "@mui/icons-material/Cancel";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import axios from "axios";
import Slide from "@mui/material/Slide";

const Read = ({ mode }) => {
  const [errstate, seterrstate] = useState(false);
  const [begin, setbegin] = useState(true);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [post_arr, setpost_arr] = useState([]);
  const [post, setpost] = useState([]);
  const [article_id, setarticle_id] = useState("");
  const [postCom, setpostCom] = useState(false);

  const [email, setemail] = useState("");
  const [comment, setcomment] = useState("");

  const containerRef = React.useRef(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  useEffect(() => {
    if (urlParams.get("post_id")) {
      var x = urlParams.get("post_id");
      mode.emit("get_post", {
        post_id: x,
      });
      mode.emit("get_post_comments", {
        post_id: x,
      });
    }
    setbegin(false);
  }, [begin]);
  useEffect(() => {
    var x = urlParams.get("post_id");
    mode.emit("view_post", {
      post_id: x,
    });
  }, []);

  const postComment = () => {
    var x = urlParams.get("post_id");
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    mode.emit("post_comment", {
      post_id: x,
      email: email,
      comment: comment,
      user_id: localStorage.getItem("userToken"),
      date: yyyy + "-" + mm + "-" + dd,
    });
  };
  const deleteComment = (val) => {
    var x = urlParams.get("post_id");
    mode.emit("delete_comment", {
      comment_id: val,
    });
  };
  const Likepost = (id) => {
    mode.emit("like_post", {
      id: id,
    });
  };

  useEffect(() => {
    mode.on("recieve_posts", (data) => {
      if (data.res) {
        data.res.map((val) => {
          setpost(val);
          setarticle_id(val.media);
        });
      }
    });
    mode.on("view_added", (data) => {
      if (data.res) {
        setbegin(true);
      }
    });
    mode.on("recieve_posts_comments", (data) => {
      if (data.res) {
        setpost_arr(data.res);
      }
    });
    mode.on("comment_posted", (data) => {
      if (data.res) {
        mode.emit("add_comment", {
          id: data.res,
        });
        seterr(
          "Your comment has been successfully added to the comment section."
        );
        seterrcat("success");
        setpostCom(false);
        seterrstate(true);
      }
    });
    mode.on("comment_deleted", (data) => {
      if (data.res) {
        mode.emit("delete_comments", {
          id: data.res,
        });
        seterr(
          "Your comment has been successfully deleted from the comment section."
        );
        seterrcat("success");
        seterrstate(true);
      }
    });
    mode.on("comments_deleted", (data) => {
      if (data.msg) {
        if (data.msg === "deleted") {
          setbegin(true);
        }
      }
    });
    mode.on("comment_added", (data) => {
      if (data.msg) {
        if (data.msg === "added") {
          setbegin(true);
        }
      }
    });
    mode.on("liked_post", (data) => {
      if (data.msg === "liked") {
        setbegin(true);
      }
    });
  }, [mode]);

  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item">
          <div
            className="back"
            style={{
              backgroundImage: `url(${article_id})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>NEWS</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>

        <div className="view">
          <div className="pad-20"></div>
          <h1>{post.title}</h1>
          <p>{post.description}</p>
          <p className="flex-bar-ver">
            <RemoveRedEyeTwoToneIcon fontSize="small" />
            &nbsp;
            {post.views > 1000
              ? post.views > 1000000
                ? (post.views / 1000000).toFixed(1) + " M"
                : (post.views / 1000).toFixed(0) + " K"
              : post.views}
            &nbsp; &nbsp; &nbsp;
            <QuestionAnswerTwoToneIcon fontSize="small" />
            &nbsp;{post_arr.length}
            &nbsp; &nbsp; &nbsp;
            <ThumbUpTwoToneIcon fontSize="small" />
            &nbsp;{post.likes}
          </p>
          <div className="flex-bar-ver">
            <div
              className="button-box"
              onClick={() => {
                if (postCom) {
                  setemail("");
                  setcomment("");
                  setpostCom(false);
                } else {
                  setpostCom(true);
                }
              }}
            >
              <QuestionAnswerTwoToneIcon fontSize="small" />
              <div className="btn-text">Comment</div>
            </div>
            <div
              className="button-box"
              onClick={() => {
                Likepost(post.share_id);
              }}
            >
              <ThumbUpTwoToneIcon fontSize="small" />
              <div className="btn-text">Like</div>
            </div>
            <div
              className="button-box"
              onClick={() => {
                var dummy = document.createElement("input"),
                  text = window.location.href;
                document.body.appendChild(dummy);
                dummy.value = text;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
                seterr(
                  `Sharing address has been copied to your clipboard successfully. Paste any where you want to share it..`
                );
                seterrcat("success");
                seterrstate(true);
              }}
            >
              <ShareTwoToneIcon fontSize="small" />
              <div className="btn-text">Share</div>
            </div>
          </div>
          {postCom ? <div className="divider-dash"></div> : null}
          {postCom ? (
            <div className="flex-bar-hor">
              <h4>Post your comment</h4>
              <p>Share your feelings or ideas about this NEWS</p>
              <div className="input-bar">
                <div className="icon">
                  <AlternateEmailIcon fontSize="small" />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      if (e.target.value.match(mailformat)) {
                        setemail(e.target.value);
                      } else {
                        setemail("");
                      }
                    }
                  }}
                />
              </div>
              <div className="input-bar">
                <textarea
                  id="txtarea"
                  className="textarea"
                  placeholder="Type your comment here!."
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      setcomment(e.target.value);
                    } else {
                      setcomment("");
                    }
                  }}
                ></textarea>
              </div>
              <div className="flex-bar-ver">
                <div
                  className="button-box"
                  onClick={() => {
                    postComment();
                  }}
                >
                  <QuestionAnswerTwoToneIcon fontSize="small" />
                  <div className="btn-text">Post</div>
                </div>
                <div
                  className="button-box"
                  onClick={() => {
                    setemail("");
                    setcomment("");
                    setpostCom(false);
                  }}
                >
                  <CancelIcon fontSize="small" />
                  <div className="btn-text">Cancel</div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="divider-dash"></div>
          <h4>Latest Comments</h4>
          {post_arr.length > 0 ? (
            post_arr.map((comment) => {
              return (
                <div className="submittion-card" key={comment.id}>
                  <div className="card-det">
                    <h4>
                      <div className="card-flex">
                        <QuestionAnswerTwoToneIcon fontSize="small" />
                        &nbsp;
                        {comment.publisher}
                      </div>
                      <div className="card-flex">
                        {comment.publisher_id ===
                        localStorage.getItem("userToken") ? (
                          <a
                            className="tr-btn"
                            onClick={() => {
                              deleteComment(comment.id);
                            }}
                          >
                            <DeleteRoundedIcon fontSize="small" />
                            Delete
                          </a>
                        ) : null}
                      </div>
                    </h4>
                    <p className="detail">{comment.comment}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Comments Posted Yet</p>
          )}
        </div>
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Read;
