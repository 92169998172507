import "./App.css";
import "./Sign.css";
import "./Home.css";
import "./Media.css";
import "./Account.css";
import "./Footer.css";
import "./Header.css";
import io from "socket.io-client";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "./er.png";
import { v4 as uuidV4 } from "uuid";
import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import Signup from "./comps/Signup";
import Home from "./comps/Home";
import Signin from "./comps/Signin";
import Download from "./comps/Download";
import Space from "./comps/Space";
import Support from "./comps/Support";
import Account from "./comps/Account";
import Read from "./comps/Read";
import VarifyEmail from "./comps/VarifyEmail";
import Forgot from "./comps/Forgot";
import About from "./comps/About";
import Questions from "./comps/Questions";
import Terms from "./comps/Terms";
import moment from "moment";
import Privacy from "./comps/Privacy";
import SearchSpace from "./comps/SearchSpace";
import UserGuide from "./comps/UserGuide";
import Profile from "./comps/school/Profile";
var CryptoJS = require("crypto-js");
const socket = io.connect("https://app.dspace.lk");

function App() {
  

  const [post_arr, setpost_arr] = useState([]);
  const [profile, setprofile] = useState([]);
  const [Ams, setAms] = useState("");
  const [loading, setloading] = useState(false);

  if (!localStorage.getItem("userToken")) {
    localStorage.setItem("userName", "");
    localStorage.setItem("userToken", uuidV4());
    localStorage.setItem("userSign", false);
    localStorage.setItem("userKey", "");
    localStorage.setItem("userPlan", "free");
    localStorage.setItem("userSubscription", "no");
    localStorage.setItem("userVarify", "no");
    localStorage.setItem("userEmail", "");
    localStorage.setItem("userTempEmail", "");
  }  


  useEffect(() => {
      Posts();
      Download_one();
      setloading(true);
      var AmPm = moment().format("a");
      var ho = moment().format("h");
      if (ho < 12 && AmPm === "am") {
        setAms("Good Morning Dear");
      }
      if (ho < 3 && AmPm === "pm") {
        setAms("Good Afternoon Dear");
      }
      if (ho > 3 && ho < 6 && AmPm === "pm") {
        setAms("Good Evening Dear");
      }
      if (ho > 6 && AmPm === "pm") {
        setAms("Good Night Dear");
      }
  
      if (localStorage.getItem("userLoggedAt")) {
        var x = localStorage.getItem("userLoggedAt");
        var y = moment(x, "YYYYMMDD, h:mm a").fromNow();
  
        if (
          y.includes("days ago") ||
          y.includes("day ago") ||
          y.includes("12 hours ago")
        ) {
          localStorage.setItem("userSign", false);
          localStorage.setItem("userLoggedAt", "");
        }
      }
      
    if (localStorage.getItem("userSign") === "true") {
        Join();
        Posts();
        Subs();
        Download_one();
        setloading(false);
      } else {
        Posts();
        Download_one();
        localStorage.setItem("userSubscription", "no");
        localStorage.setItem("userVarify", "no");
        setloading(false);
      }
      


  }, []);

  const Join = () => {
    socket.emit("join_room", { token: localStorage.getItem("userToken") });
  };
  const Posts = () => {
    socket.emit("get_posts", { token: localStorage.getItem("userToken") });
  };
  const Subs = () => {
    socket.emit("get_subs", { token: localStorage.getItem("userToken") });
  };
  const Up_Sub_mail = () => {
    socket.emit("update_subscribed_email", { token: localStorage.getItem("userToken"), email: localStorage.getItem("userEmail") });
  };
  const Download_one = () => {
    socket.emit("downloaded_one", { token: localStorage.getItem("userToken")});
  };

  useEffect(() => {
    
    socket.on("sub_ok", (data) => {
        localStorage.setItem("userSubscription", "yes");
    });
    socket.on("receive_subs", (data) => {
      if (data.res) {
        var bytes = CryptoJS.AES.decrypt(
          data.res,
          localStorage.getItem("userToken")
        );
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        [decryptedData].map((val) => {
          if (val.email !== localStorage.getItem("userEmail")) {
            Up_Sub_mail();
          } else {
            localStorage.setItem("userSubscription", "yes");
          }
        });
      }
    });
    socket.on("receive_user", (data) => {
      var bytes = CryptoJS.AES.decrypt(
        data.res,
        localStorage.getItem("userToken")
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      setprofile([decryptedData]);
      [decryptedData].map((val) => {
        if (val.varified === "no") {
          localStorage.setItem("userVarify", "no");
          localStorage.setItem("userTempEmail", val.email);
          localStorage.setItem("userEmail", "");
        } else {
          localStorage.setItem("userVarify", "yes");
          localStorage.setItem("userEmail", val.email);
          localStorage.setItem("userTempEmail", "");
          localStorage.setItem("userName", val.name);
        }
      });
    });
    socket.on("receive_posts", (data) => {
      setpost_arr(data.res);
    });
    socket.on("download_ok", (data) => {
      if (data.res) {
        var bytes = CryptoJS.AES.decrypt(
          data.res,
          localStorage.getItem("userToken")
        );
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        [decryptedData].map((val) => {
          if (val.date.length > 0) {
            localStorage.setItem("userKeyExpire", val.date);
            localStorage.setItem("userKey", val.key_numb);
          }
        });
      }
    });
  }, [socket]);

  return (
    <div className="App">
      {loading ? (
        <div className="isUp">
          <div className="upl">
            <div className="img">
              <i className="fa fa-spin fa-circle-notch"></i>
            </div>
          </div>
        </div>
      ) : null}
      <header className="App-header">
        <nav className="name">
          <div className="flex-div">
            <a href="/home">
              <div className="cover"></div>{" "}
              <img src={logo} alt="" className="img fa-spin" />
            </a>
            <a href="/download">
              <BrowserUpdatedOutlinedIcon fontSize="medium" />
            </a>
            <a href="/news">
              <RocketLaunchOutlinedIcon fontSize="medium" />
            </a>
            <a href="/support">
              <SupportOutlinedIcon fontSize="medium" />
            </a>
            <a href="/account">
              <AccountCircleOutlinedIcon fontSize="medium" />
            </a>
          </div>
        </nav>
      </header>
      <div className="body">
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/home"
              element={<Home mode={socket} ampm={Ams} />}
            />
            <Route exact path="/" element={<Home mode={socket} />} />
            <Route exact path="/educt_schools" element={<Profile mode={socket} />} />
            <Route exact path="/educt_user_guide" element={<UserGuide mode={socket} />} />
            <Route
              exact
              path="/search"
              element={<SearchSpace posts={post_arr} />}
            />
            <Route exact path="/privacy_policy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/about" element={<About />} />
            <Route
              exact
              path="/questions"
              element={<Questions mode={socket} />}
            />
            <Route
              exact
              path="/forgot_password"
              element={<Forgot mode={socket} />}
            />
            <Route
              exact
              path="/varify_email"
              element={<VarifyEmail mode={socket} />}
            />
            <Route
              exact
              path="/news"
              element={<Space mode={socket} posts={post_arr} />}
            />
            <Route exact path="/read" element={<Read mode={socket} />} />
            <Route
              exact
              path="/account"
              element={<Account mode={socket} data={profile} />}
            />
            <Route exact path="/support" element={<Support mode={socket} />} />
            <Route exact path="/download" element={<Download mode={socket} />} />
            <Route exact path="/signin" element={<Signin mode={socket} />} />
            <Route exact path="/signup" element={<Signup mode={socket} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
