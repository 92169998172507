import React, { useEffect, useState, useRef } from "react";
import Footer from "./Footer";
import MessageBox from "./MessageBox";
import axios from "axios";
import Banner from "./Banner";
import logo from "../wt.png";
import pic from "./assets/lg.png";
import LiveHelpTwoToneIcon from "@mui/icons-material/LiveHelpTwoTone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import Slide from "@mui/material/Slide";

const Questions = ({ mode }) => {
  const [begin, setbegin] = useState(true);
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [email, setemail] = useState("");
  const [detail, setdetail] = useState("");
  const [ask, setask] = useState(false);
  const [questions, setquestions] = useState([]);

  const containerRef = React.useRef(null);

  useEffect(() => {
    mode.emit("get_faq");

    setbegin(false);
  }, [begin]);

  const postQuestion = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      if (detail.length > 5) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        mode.emit("post_quest", {
          email: email,
          date: yyyy + "-" + mm + "-" + dd,
          detail: detail,
          device_id: localStorage.getItem("userToken"),
        });
      } else {
        seterr(
          "Hmm... are you trying to miss use our question platform? Ask a reasonable question."
        );
        seterrcat("failed");
        seterrstate(true);
      }
    } else {
      seterr(
        "Your email is not in valid format. Please correct it before posting question."
      );
      seterrcat("failed");
      seterrstate(true);
    }
  };

  const deleteQuestion = (id) => {
    mode.emit("delete_question", {
      id: id,
    });
  };

  useEffect(() => {
    mode.on("quest_added", (data) => {
      if (data.msg === "added") {
        seterr(
          "Your question has been successfully submitted on our FAQ section. Please keep in touch of your mailbox."
        );
        seterrcat("success");
        seterrstate(true);
        setask(false);
        setbegin(true);
      }
    });
    mode.on("quest_deleted", (data) => {
      if (data.msg === "deleted") {
        seterr(
          "Your question has been successfullt removed from our question section."
        );
        seterrcat("success");
        seterrstate(true);
        setbegin(true);
      }
    });
    mode.on("res_faq", (data) => {
      if (data.res) {
        setquestions(data.res);
      }
    });
  }, [mode]);

  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item">
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>DSPACE</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>Any Questions?</h1>
          <p>Ask your questions about Dark Leo Company or Dspace.</p>
          {ask ? (
            <div className="flex-bar-hor">
              <div className="input-bar">
                <div className="icon">
                  <AlternateEmailIcon fontSize="small" />
                </div>
                {localStorage.getItem("userEmail").length > 0 ? (
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Valid Email"
                    id="email"
                    value={localStorage.getItem("userEmail")}
                    onKeyUp={(e) => {
                      if (e.target.value.length > 0) {
                        setemail(e.target.value);
                      } else {
                        setemail("");
                      }
                    }}
                    required
                  />
                ) : (
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Valid Email"
                    id="email"
                    onKeyUp={(e) => {
                      if (e.target.value.length > 0) {
                        setemail(e.target.value);
                      } else {
                        setemail("");
                      }
                    }}
                    required
                  />
                )}
              </div>
              <div className="input-bar">
                <textarea
                  id="txtarea"
                  className="textarea"
                  placeholder="What is your question?"
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      setdetail(e.target.value);
                    } else {
                      setdetail("");
                    }
                  }}
                ></textarea>
              </div>
              <div className="flex-bar-ver">
                <div
                  className="button-box"
                  onClick={() => {
                    postQuestion();
                  }}
                >
                  <LiveHelpTwoToneIcon fontSize="small" />
                  <div className="btn-text">Post Question</div>
                </div>
                <div
                  className="button-box"
                  onClick={() => {
                    setask(false);
                  }}
                >
                  <CancelIcon fontSize="small" />
                  <div className="btn-text">Cancel</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-bar-ver">
              <div
                className="button-box"
                onClick={() => {
                  setask(true);
                }}
              >
                <LiveHelpTwoToneIcon fontSize="small" />
                <div className="btn-text">Ask Question</div>
              </div>
            </div>
          )}
        </div>
        <div className="view">
          <div className="flex-bar-hor">
            <h4>Previous Questions</h4>
            <div className="divider-dash"></div>
            {questions.length > 0 ? (
              questions.map((quest) => {
                return (
                  <div className="submittion-card" key={quest.id}>
                    <div className="card-det">
                      <h4>
                        <div className="card-flex">
                          <LiveHelpTwoToneIcon fontSize="small" />
                          &nbsp;
                          {quest.email}
                        </div>
                        <div className="card-flex">
                          {quest.device_id ===
                          localStorage.getItem("userToken") ? (
                            <a
                              className="tr-btn"
                              onClick={() => {
                                deleteQuestion(quest.id);
                              }}
                            >
                              <DeleteRoundedIcon fontSize="small" />
                            </a>
                          ) : null}
                        </div>
                      </h4>
                      <p className="detail">
                        {quest.date} : <br />
                        {quest.quest}
                      </p>
                      <p className="detail answer">{quest.answer}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No Questions Asked Yet</p>
            )}
          </div>
        </div>
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Questions;
