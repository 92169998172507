import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../er.png";
import pic from "./assets/dd.gif";
import gr from "./assets/gr.png";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import { v4 as uuidV4 } from "uuid";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import MessageBox from "./MessageBox";

var randomString = require("random-string");

const Signup = ({ mode }) => {
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [reEmail, setreEmail] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [rePassword, setrePassword] = useState("");
  const [check, setcheck] = useState(false);

  const signup = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var ranD = randomString({
      length: 6,
      numeric: true,
      letters: false,
      special: false,
      exclude: ["1"],
    });

    if (email.match(mailformat)) {
      if (password.length > 6) {
        if (check) {
          var newId = uuidV4();
          mode.emit("signup", {
            token: newId,
            email: email,
            password: password,
            varify: ranD,
          });
        } else {
          seterr(
            "You must accept to our terms and conditions to apply the registration."
          );
          seterrcat("failed");
          seterrstate(true);
        }
      } else {
        seterr(
          "Your password must be more than 6 characters. Please insert correct password!."
        );
        seterrcat("failed");
        seterrstate(true);
      }
    } else {
      seterr("Your email address might be invalid. Please correct it first!.");
      seterrcat("failed");
      seterrstate(true);
    }
  };

  useEffect(() => {
    mode.on("signup_ok", (data) => {
      if (data.msg === "created") {
        seterr("Welcome! You have successfully signed up on Dspace.");
        seterrcat("success");
        seterrstate(true);

        localStorage.setItem("userToken", data.id);
        window.location.href = "/signin";
      }
      if (data.msg === "have") {
        seterr(
          "We have your email on our data base. please Sign in to Dspace using this Email."
        );
        seterrcat("failed");
        seterrstate(true);
        localStorage.setItem("userToken", data.id);
        window.location.href = "/signin";
      }
    });
  }, [mode]);

  return (
    <div className="signin">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="form">
        <div className="form-icon">
          <img
            src={logo}
            alt=""
            onClick={() => {
              window.location.href = "/home";
            }}
          />
        </div>
        <div className="form-pic ex">
          <img src={pic} alt="" />
        </div>
        <div className="form-title">Create Account</div>
        <div className="form-input">
          <div className="input-bar">
            <div className="icon">
              <PersonIcon fontSize="small" />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              id="email"
              onKeyUp={(e) => {
                if (e.target.value.length > 0) {
                  setreEmail(e.target.value);
                }
              }}
              required
            />
          </div>
          <div className="input-bar">
            <div className="icon">
              <PersonIcon fontSize="small" />
            </div>
            <input
              type="email"
              name="reemail"
              placeholder="Re-Enter Email"
              id="reemail"
              onKeyUp={(e) => {
                if (e.target.value.length > 0) {
                  if (e.target.value === reEmail) {
                    setemail(e.target.value);
                  }
                }
              }}
              required
            />
          </div>
          <div className="input-bar">
            <div className="icon">
              <PasswordIcon fontSize="small" />
            </div>
            <input
              type="password"
              className="pass"
              name="password"
              placeholder="Password"
              id="password"
              onKeyUp={(e) => {
                if (e.target.value.length >= 6) {
                  setrePassword(e.target.value);
                }
              }}
              required
            />
          </div>
          <div className="input-bar">
            <div className="icon">
              <PasswordIcon fontSize="small" />
            </div>
            <input
              type="password"
              className="pass"
              name="confirm password"
              placeholder="Confirm Password"
              id="confirmpassword"
              onKeyUp={(e) => {
                if (e.target.value.length >= 6) {
                  if (e.target.value === rePassword) {
                    setpassword(e.target.value);
                  }
                }
              }}
              required
            />
          </div>
        </div>
        <div className="form-link">
          <p>
            <input
              type="checkbox"
              name="check"
              id="check"
              onChange={(e) => {
                if (e.target.checked) {
                  setcheck(true);
                } else {
                  setcheck(false);
                }
              }}
            />
            <label htmlFor="check">
              &nbsp;I accept to all the terms and conditions of Dspace.
            </label>
          </p>
        </div>
        <div className="form-button">
          <div
            className="button-box"
            onClick={() => {
              signup();
            }}
          >
            <PersonAddAlt1OutlinedIcon fontSize="small" />
            <div className="btn-text">Sign up</div>
          </div>
          <a href="/signin" className="button-box">
            <LoginOutlinedIcon fontSize="small" />
            <div className="btn-text">Sign In</div>
          </a>
        </div>
        <div className="form-bottom">
          <div className="footer-bar">
            <div className="flex-bar">
              <a href="/home" className="trans-btn">
                Home
              </a>
              <a href="/download" className="trans-btn">
                Download
              </a>
              <a href="/space" className="trans-btn">
                Space
              </a>
              <a href="/support" className="trans-btn">
                Support
              </a>
              <a href="/about" className="trans-btn">
                About
              </a>
            </div>
            <div className="img">
              <img src={gr} alt="" />
            </div>
            <h4>&copy;2022 DSPACE</h4>
            <p>
              By <br /> Dark Leo - Ceylon
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
