import React, { useState, useRef } from "react";
import Footer from "./Footer";
import MessageBox from "./MessageBox";
import Banner from "./Banner";
import logo from "../wt.png";
import pic from "./assets/lg.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import Slide from "@mui/material/Slide";

const About = () => {
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");

  const containerRef = React.useRef(null);

  const fblink = "https://www.facebook.com";
  const ytlink = "https://www.youtube.com";
  const instalink = "https://www.instagram.com";
  const twlink = "https://www.twitter.com";
  const gitlink = "https://www.github.com";

  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item" ref={containerRef}>
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>DSPACE</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>About Dark Leo</h1>
          <p>
            Venerable Vithikuliye Dhammarathana is the founder and inventor of
            Dark Leo company. This company is the result of a hard work and lots
            of fallbacks. We never gave up on any challenge that we met. We kept
            on running to the success. Ta Daa!.. Here we are.
            <br />
            <br />
            We have one fact that reacently helped to our success. That is your
            feedback. Always we hoping your advices so gratefully. One final
            thing to say. Never give up on your ambition. It might be wrong. But
            you have the right to change it into the best ambition you got.
            <br />
            <br />
          </p>
        </div>
        <div className="divider-dash"></div>
        <div className="view">
          <h4>Our contact details</h4>
          <div className="flex-bet">
            <p className="bold">Office Address</p>
            <p
              onClick={() => {
                var dummy = document.createElement("input"),
                  text =
                    "Sri Sudharmaramaya, Weligodapitiya, Polgahawela, 60300, Sri Lanka";

                document.body.appendChild(dummy);
                dummy.value = text;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
                seterr(
                  `You have copied our live location address successfully.`
                );
                seterrcat("success");
                seterrstate(true);
              }}
            >
              Sri Sudharmaramaya,
              <br />
              Weligodapitiya,
              <br />
              Polgahawela,
              <br />
              60300,
              <br />
              Sri Lanka
            </p>
          </div>
          <div className="flex-bet">
            <p className="bold">Email Address</p>
            <p
              onClick={() => (window.location.href = "mailto:Admin@dspace.lk")}
            >
              Admin@dspace.lk
            </p>
          </div>
          <div className="flex-bet">
            <p className="bold">Phone Number</p>
            <p onClick={() => (window.location.href = "tel:+94768463836")}>
              +9476 84 63 836
            </p>
          </div>
          <div className="flex-bet">
            <p className="bold">Social Accounts</p>
            <p className="flex-ff">
              <a href={fblink}>
                <FacebookIcon fontSize="medium" />
              </a>
              <a href={ytlink}>
                <YouTubeIcon fontSize="medium" />
              </a>
              <a href={instalink}>
                <InstagramIcon fontSize="medium" />
              </a>
              <a href={twlink}>
                <TwitterIcon fontSize="medium" />
              </a>
              <a href={gitlink}>
                <GitHubIcon fontSize="medium" />
              </a>
            </p>
          </div>
        </div>
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default About;
