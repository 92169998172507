import React, { useEffect, useState } from "react";
import logord from "./assets/er.png";
import Footer from "./Footer";
import Countdown from "react-countdown";
import MailLockTwoToneIcon from "@mui/icons-material/MailLockTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import axios from "axios";
import MessageBox from "./MessageBox";
var randomString = require("random-string");

const VarifyEmail = ({ mode }) => {
  const [code, setcode] = useState("");
  const [resend, setresend] = useState(false);

  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");

  const Completionist = () => (
    <div
      className="var-tbn"
      onClick={() => {
        if (!resend) {
          resender();
          setresend(true);
        }
      }}
    >
      <SendTwoToneIcon fontSize="small" />
      &nbsp;<p>Resend Code</p>
    </div>
  );
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setresend(false);
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <p>
          Please wait 0{minutes} : {seconds > 9 ? seconds : `0${seconds}`}{" "}
          seconds to resend your code.
        </p>
      );
    }
  };
  const resender = () => {
    var ranD = randomString({
      length: 6,
      numeric: true,
      letters: false,
      special: false,
      exclude: ["6", "4", "1"],
    });
    mode.emit("send_mailto", {
      email: localStorage.getItem("userTempEmail"),
      varify: ranD,
      user:
        localStorage.getItem("userName").length > 0
          ? localStorage.getItem("userName")
          : "User",
      device_id: localStorage.getItem("userToken"),
    });
  };
  const varifyCode = () => {
    mode.emit("varify_code", {
      vcode: code,
      device_id: localStorage.getItem("userToken"),
    });
  };

  useEffect(() => {
    mode.on("varified", (data) => {
      if (data.msg === "matched") {
        seterr(
          `Your account has been varified successfully. Thanks for being with us.`
        );
        seterrcat("success");
        seterrstate(true);
        localStorage.setItem(
          "userEmail",
          localStorage.getItem("userTempEmail")
        );
        localStorage.setItem("userTempEmail", "");
        localStorage.setItem("userVarify", "yes");
        window.location.href = "/home";
      }
    });
    mode.on("sent", (data) => {
      if (data.msg === "sent") {
        seterr(
          `We have successfully sent the new varification code to your email address : ${localStorage.getItem(
            "userEmail"
          )}.`
        );
        seterrcat("success");
        seterrstate(true);
      }
    });
  }, [mode]);
  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <div className="view">
          <div className="pad-20"></div>
          <img src={logord} className="img-var" alt="" />
          <h4>Varify Email Address</h4>
          <p>
            Please check your mailbox and enter the varification code below.
          </p>
          <div className="flex-bar-hor">
            <div className="input-bar">
              <input
                type="text"
                name="varify"
                id="varify"
                style={{
                  textAlign: "center",
                  letterSpacing: "10px",
                  paddingLeft: "0",
                }}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setcode(e.target.value);
                  } else {
                    setcode("");
                  }
                }}
              />
            </div>
          </div>
          {code.length > 5 ? (
            <div
              className="button-box"
              onClick={() => {
                varifyCode();
              }}
            >
              <MailLockTwoToneIcon fontSize="small" />
              <div className="btn-text">Varify Now</div>
            </div>
          ) : null}
          {resend ? (
            <Countdown date={Date.now() + 119000} renderer={renderer} />
          ) : (
            <div
              className="var-tbn"
              onClick={() => {
                if (!resend) {
                  resender();
                  setresend(true);
                }
              }}
            >
              <SendTwoToneIcon fontSize="small" />
              &nbsp;<p>Resend Code</p>
            </div>
          )}
        </div>
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default VarifyEmail;
