import React, { useState, useRef, useEffect } from "react";
import logo from "../wt.png";
import pic from "./assets/aa.gif";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import Banner from "./Banner";
import Footer from "./Footer";
import MessageBox from "./MessageBox";
import axios from "axios";
import Slide from "@mui/material/Slide";
var countryTelData = require("country-telephone-data");
var randomString = require("random-string");

const Account = ({ mode, data = [] }) => {
  const profile = data;
  const [edit, setedit] = useState(false);
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [country, setcountry] = useState("");
  const [uploadHappening, setuploadHappening] = useState(false);

  var countryCodes = countryTelData.allCountries.sort();
  const containerRef = React.useRef(null);

  const saveChanges = () => {
    if (name.length > 3) {
      mode.emit("update_country", {
        username: name,
        device_id: localStorage.getItem("userToken"),
      });
    }
    if (country.length > 3) {
      mode.emit("update_country", {
        country: country,
        device_id: localStorage.getItem("userToken"),
      });
    }
    if (email.length > 3) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email.match(mailformat)) {
        var ranD = randomString({
          length: 6,
          numeric: true,
          letters: false,
          special: false,
          exclude: ["6", "4", "1"],
        });
        mode.emit("update_email", {
          email: email,
          rand: ranD,
          user:
            localStorage.getItem("userName").length > 0
              ? localStorage.getItem("userName")
              : "User",
          device_id: localStorage.getItem("userToken"),
        });
      } else {
        seterr("Your email is invalid. Please insert correct email address!.");
        seterrcat("failed");
        seterrstate(true);
      }
    }
    if (phone.length > 9) {
      let text = phone;
      let letter = text.charAt(0);
      if (letter === "0") {
        mode.emit("update_phone", {
          phone: phone.substr(1),
          device_id: localStorage.getItem("userToken"),
        });
      } else {
        mode.emit("update_phone", {
          phone: phone,
          device_id: localStorage.getItem("userToken"),
        });
      }
    }
  };

  useEffect(() => {
    mode.on("updated_email", (data) => {
      if (data.msg === "updated") {
        setemail("");
        setedit(false);
        window.location.reload();
      }
    });
    mode.on("updated_country", (data) => {
      if (data.msg === "updated") {
        setcountry("");
        setedit(false);
        window.location.reload();
      }
    });
    mode.on("updated_name", (data) => {
      if (data.msg === "updated") {
        setname("");
        setedit(false);
        window.location.reload();
      }
    });
    mode.on("updated_phone", (data) => {
      if (data.msg === "updated") {
        setphone("");
        setedit(false);
        window.location.reload();
      }
    });
    mode.on("updated_photo", (data) => {
      if (data.msg === "updated") {
        seterrstate(true);
        setuploadHappening(false);
        seterr("Your profile picture has updated successfully!.");
        seterrcat("success");
        window.location.reload();
      }
    });
  }, [mode]);

  return (
    <div className="scrl">
      {uploadHappening ? (
        <div className="isUp">
          <div className="upl">
            <div className="img">
              <i className="fa fa-spin fa-circle-notch"></i>
            </div>
          </div>
        </div>
      ) : null}
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item">
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>DSPACE</h1>
          </Slide>
          <div
            id="dec-cir1"
            onClick={() => {
              if (localStorage.getItem("userSign") === "false") {
              } else {
                var x = document.getElementById("file");
                x.click();
              }
            }}
          >
            <div className="image">
              <img
                className="nala"
                src={
                  profile.length > 0
                    ? profile.map((val) => {
                        if (val.photo !== null) {
                          return val.photo;
                        } else {
                          return pic;
                        }
                      })
                    : pic
                }
                alt=""
              />
            </div>

            <input
              type="file"
              name="file"
              id="file"
              hidden
              onChange={(e) => {
                if (e.target.files[0].name.length > 0) {
                  let reader = new FileReader();
                  reader.onloadend = function() {
                    setuploadHappening(true);
                    mode.emit("update_photo", {
                      photo: reader.result,
                      device_id: localStorage.getItem("userToken"),
                    });
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }
              }}
            />
          </div>
        </div>
        {localStorage.getItem("userSign") === "true" ? (
          profile.length > 0 ? (
            profile.map((val) => {
              return (
                <div className="view" key={val.id}>
                  <div className="pad-20"></div>
                  <h1>User Profile</h1>
                  <div className="flex-bet">
                    <p className="bold">Name</p>
                    <p>
                      {edit ? (
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder={val.name}
                          onChange={(e) => {
                            setname(e.target.value);
                          }}
                        />
                      ) : val.name !== null ? (
                        val.name
                      ) : (
                        "Enter your fullname"
                      )}
                    </p>
                  </div>
                  <div className="flex-bet">
                    <p className="bold">Email Address</p>
                    <p>
                      {edit ? (
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder={val.email}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                      ) : val.varified === "yes" ? (
                        val.email
                      ) : (
                        "Varify your email please"
                      )}
                    </p>
                  </div>
                  <div className="flex-bet">
                    <p className="bold">Country</p>
                    <p>
                      {edit ? (
                        <select
                          name="country"
                          id="country"
                          onChange={(e) => {
                            setcountry(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {val.country !== null
                              ? val.country
                              : "Select A Country"}
                          </option>
                          {countryCodes.map((val1) => {
                            return (
                              <option
                                value={`${val1.iso2.toUpperCase()} : +${
                                  val1.dialCode
                                }`}
                              >{`${val1.iso2.toUpperCase()} : +${
                                val1.dialCode
                              }`}</option>
                            );
                          })}
                        </select>
                      ) : val.country !== null ? (
                        val.country
                      ) : (
                        "Not Selected Yet"
                      )}
                    </p>
                  </div>
                  <div className="flex-bet">
                    <p className="bold">Phone</p>
                    <p>
                      {edit ? (
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder={val.phone}
                          onChange={(e) => {
                            setphone(e.target.value);
                          }}
                        />
                      ) : val.phone !== null ? (
                        val.phone
                      ) : (
                        "Enter your phone"
                      )}
                    </p>
                  </div>

                  <div className="flex-bet">
                    <p className="bold">EDUCT Key</p>
                    <p>
                      {localStorage.getItem("userKey").length > 0
                        ? localStorage.getItem("userKey")
                        : "You haven't download EDUCT yet."}
                    </p>
                  </div>

                  {edit ? (
                    <div className="flex-bar-ver">
                      <a
                        className="button-box"
                        onClick={() => {
                          saveChanges();
                        }}
                      >
                        <SaveIcon fontSize="small" />
                        <div className="btn-text">Save</div>
                      </a>
                      <a
                        className="button-box"
                        onClick={() => {
                          setedit(false);
                        }}
                      >
                        <CancelIcon fontSize="small" />
                        <div className="btn-text">Close</div>
                      </a>
                    </div>
                  ) : (
                    <div className="flex-bar-ver">
                      <a
                        className="button-box"
                        onClick={() => {
                          localStorage.setItem("userSign", false);
                          seterr(
                            `You are now about to sign out from Dspace. Hope you may come back. See you later!.`
                          );
                          seterrcat("success");
                          seterrstate(true);
                        }}
                      >
                        <LogoutIcon fontSize="small" />
                        <div className="btn-text">Sign Out</div>
                      </a>
                      <a
                        className="button-box"
                        onClick={() => {
                          setedit(true);
                        }}
                      >
                        <EditIcon fontSize="small" />
                        <div className="btn-text">Edit</div>
                      </a>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="view">
              <div className="flex-bar-hor">
                <h1>User Profile</h1>
                <div className="flex-bar-ver">
                  <RefreshIcon fontSize="small" className="fa-spin" />
                  <p>Loading</p>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="view">
            <div className="pad-20"></div>
            <h1>User Profile</h1>
            <p>
              This page content will be only visible to signed members only.
              Please sign in or create an account for get access to the page
              content.
            </p>
            <div className="flex-bar-ver">
              <a href="/signin" className="button-box">
                <LoginOutlinedIcon fontSize="small" />
                <div className="btn-text">Sign In</div>
              </a>
              <a href="/signup" className="button-box">
                <PersonAddAlt1OutlinedIcon fontSize="small" />
                <div className="btn-text">Sign up</div>
              </a>
            </div>
          </div>
        )}
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Account;
