import React, { useEffect, useRef } from "react";
import axios from "axios";
import logo from "../wt.png";
import pic from "./assets/ww.gif";
import logord from "./assets/er.png";
import Countdown from "react-countdown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import CancelIcon from "@mui/icons-material/Cancel";
import Banner from "./Banner";
import Footer from "./Footer";
import moment from "moment";
import MessageBox from "./MessageBox";
import Slide from "@mui/material/Slide";
import { useState } from "react";
var randomString = require("random-string");
var QRCodeStyling = require("qr-code-styling");

var CryptoJS = require("crypto-js");

const Download = ({ mode }) => {
  const [download, setdownload] = useState(false);
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [btns, setbtns] = useState(false);

  const containerRef = React.useRef(null);

  useEffect(() => {
    if (localStorage.getItem("userKey")) {
      GenQr(localStorage.getItem("userKey").toUpperCase());
    }
    if (localStorage.getItem("userKeyExpire")) {
      var x = localStorage.getItem("userKeyExpire");
      var y = moment(x, "MM/DD/YYYY").fromNow();
      if (
        y.includes("days ago") ||
        y.includes("day ago") ||
        y.includes("hours ago") ||
        y.includes("month ago") ||
        y.includes("months ago") ||
        y.includes("year ago") ||
        y.includes("years ago")
      ) {
        localStorage.setItem("KeyExpired", "true");
      } else {
        localStorage.setItem("KeyExpired", "false");
      }
    }
  }, []);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setbtns(true);
      setdownload(false);
    } else {
      // Render a countdown
      return (
        <div className="flex-bar-hor">
          <div className="flex-bar img-gd">
            <img src={logord} className="fa-spin img" alt="" />
            {seconds === 5 ? (
              <p>Processing Download.</p>
            ) : seconds === 4 ? (
              <p>Processing Download..</p>
            ) : seconds === 3 ? (
              <p>Processing Download...</p>
            ) : seconds === 2 ? (
              <p>Processing Download....</p>
            ) : seconds === 1 ? (
              <p>Processing Download.....</p>
            ) : null}
          </div>
        </div>
      );
    }
  };

  const GenQr = (val) => {
    const qrCode = new QRCodeStyling({
      width: 180,
      height: 180,
      data: val,
      margin: 3,
      qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "H" },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.2, margin: 0 },
      dotsOptions: { type: "extra-rounded", color: "#ff6600" },
      backgroundOptions: { color: "#ffffff" },
      image: logord,
      dotsOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#6a1a4c",
          color2: "#6a1a4c",
          rotation: "0",
        },
      },
      cornersSquareOptions: { type: "extra-rounded", color: "#ff6600" },
      cornersSquareOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      cornersDotOptions: { type: "", color: "#0091ff" },
      cornersDotOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      backgroundOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
        },
      },
    });
    var x = document.getElementById("canvas");
    if (isNaN(x)) {
      x.innerHTML = "";
      qrCode.append(x);
    }
  };
  const downloadApp = () => {
    mode.emit("downloaded_one", {
      token: localStorage.getItem("userToken"),
      keyUp: localStorage.getItem("userKey").toUpperCase(),
      sta: localStorage.getItem("userPlan"),
      date:
        localStorage.getItem("userPlan") === "free"
          ? `${moment()
              .add(30, "days")
              .calendar()}`
          : localStorage.getItem("userPlan") === "advance"
          ? `${moment()
              .add(365, "days")
              .calendar()}`
          : localStorage.getItem("userPlan") === "professional"
          ? `${moment()
              .add(1095, "days")
              .calendar()}`
          : null,
    });
  };

  const setKeyAddress = () => {
    var ranD = randomString({
      length: 16,
      numeric: true,
      letters: true,
      special: false,
      exclude: ["a", "b", "1"],
    });
    if (localStorage.getItem("userKey").length > 0) {
      setdownload(true);
    } else {
      localStorage.setItem("userKey", ranD.toUpperCase());
      seterr(
        `New key generated for your ${localStorage.getItem(
          "userPlan"
        )} package is now activated! Please use it to activate programe according to your package.`
      );
      seterrcat("success");
      seterrstate(true);
      setdownload(true);
      GenQr(ranD.toUpperCase());
    }
  };

  const down = () => {
    var date =
      localStorage.getItem("userPlan") === "free"
        ? `${moment()
            .add(30, "days")
            .calendar()}`
        : localStorage.getItem("userPlan") === "advance"
        ? `${moment()
            .add(365, "days")
            .calendar()}`
        : localStorage.getItem("userPlan") === "professional"
        ? `${moment()
            .add(1095, "days")
            .calendar()}`
        : null;
    localStorage.setItem("userKeyExpire", date);
    var a = document.createElement("a");
    a.setAttribute("href", "https://www.dspace.lk/data/educt.msi");
    a.setAttribute("download", "EductEMS.msi");
    a.click();
    seterr(
      `Your software download has been started. Use your key to activate it.`
    );
    seterrcat("success");
    seterrstate(true);
    setdownload(false);
    setbtns(false);
  };
  useEffect(() => {
    mode.on("inserted_d", (data) => {
      down();
    });
    mode.on("download_ok", (data) => {
      if (data.res) {
        var bytes = CryptoJS.AES.decrypt(
          data.res,
          localStorage.getItem("userToken")
        );
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        [decryptedData].map((val) => {
          if (val.date.length > 0) {
            localStorage.setItem("userKeyExpire", val.date);
            localStorage.setItem("userKey", val.key_numb);
          }
        });
      }
    });
  }, [mode]);

  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item">
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>EDUCT&trade;</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        {localStorage.getItem("userSign") === "true" ? (
          <>
            {localStorage.getItem("KeyExpired") === "True" ? null : (
              <div className="view">
                <div className="pad-20"></div>
                <h1>EDUCT KEY</h1>

                {localStorage.getItem("KeyExpired") === "true" ? (
                  <p>
                    {localStorage.getItem("userKey")} : This key was expired!
                  </p>
                ) : (
                  <h4>{localStorage.getItem("userKey")}</h4>
                )}
                {localStorage.getItem("KeyExpired") === "true" ? null : (
                  <p>
                    The above key will be expires{" "}
                    {moment(
                      localStorage.getItem("userKeyExpire"),
                      "MM/DD/YYYY"
                    ).fromNow()}
                    . The last date has been set to{" "}
                    {moment(localStorage.getItem("userKeyExpire")).format(
                      `MMMM Do YYYY`
                    )}
                    .
                  </p>
                )}
                <div className="flex-bar-ver">
                  <a href="#download-section" className="button-box green">
                    <ContentCopyIcon fontSize="small" />
                    <div className="btn-text">Copy</div>
                  </a>
                  <a href="/educt_user_guide" className="button-box green">
                    <ChromeReaderModeIcon fontSize="small" />
                    <div className="btn-text">Consult</div>
                  </a>
                  <a href="/educt_schools" className="button-box green">
                    <HistoryEduIcon fontSize="small" />
                    <div className="btn-text">School</div>
                  </a>
                </div>
              </div>
            )}
            <div className="view" id="download">
              <div className="pad-20"></div>
              <h1>Choose Your Plan!</h1>
              <p>EDUCT will manage your time easily.</p>
              <div className="flex-wrap-bar">
                <div className="wrap-card">
                  <img src={logord} alt="" className="card-pic" />
                  <h3>Free</h3>
                  <p>
                    <ul>
                      <li>1 Month Trial</li>
                      <li>No Data Sync</li>
                      <li>No Warrent</li>
                      <li>Limited Scheduling</li>
                    </ul>
                  </p>

                  <div className="flex-bar">
                    {localStorage.getItem("userPlan") === "free" ? (
                      localStorage.getItem("KeyExpired") === "true" ? (
                        <a className="button-box gray">
                          <CancelIcon fontSize="small" />
                          <div className="btn-text">Expired</div>
                        </a>
                      ) : (
                        <a
                          href="#download-section"
                          className="button-box green"
                          onClick={() => {
                            setKeyAddress();
                          }}
                        >
                          <BrowserUpdatedOutlinedIcon fontSize="small" />
                          <div className="btn-text">Download</div>
                        </a>
                      )
                    ) : (
                      <a className="button-box gray">
                        <CancelIcon fontSize="small" />
                        <div className="btn-text">Not Available</div>
                      </a>
                    )}
                  </div>
                </div>
                <div className="wrap-card">
                  <img src={logord} alt="" className="card-pic" />
                  <h3>Advance</h3>
                  <p>
                    <ul>
                      <li>1 Year Warrent</li>
                      <li>1 Year Data Sync</li>
                      <li>1 Year Validity</li>
                      <li>2 Time Schedules</li>
                    </ul>
                  </p>

                  <div className="flex-bar">
                    {localStorage.getItem("userPlan") === "advance" ? (
                      localStorage.getItem("KeyExpired") === "true" ? (
                        <a className="button-box gray">
                          <CancelIcon fontSize="small" />
                          <div className="btn-text">Expired</div>
                        </a>
                      ) : (
                        <a className="button-box green">
                          <CheckCircleOutlineIcon fontSize="small" />
                          <div className="btn-text">Activated</div>
                        </a>
                      )
                    ) : (
                      <a className="button-box">
                        <ShoppingCartIcon fontSize="small" />
                        <div className="btn-text">$ 49/=</div>
                      </a>
                    )}
                  </div>
                </div>
                <div className="wrap-card">
                  <img src={logord} alt="" className="card-pic" />
                  <h3>Professional</h3>
                  <p>
                    <ul>
                      <li>3 Year Warrent</li>
                      <li>3 Year Data Sync</li>
                      <li>3 Year Validity</li>
                      <li>5 Time Schedules</li>
                    </ul>
                  </p>
                  <div className="flex-bar">
                    {localStorage.getItem("userPlan") === "professional" ? (
                      localStorage.getItem("KeyExpired") === "true" ? (
                        <a className="button-box gray">
                          <CancelIcon fontSize="small" />
                          <div className="btn-text">Expired</div>
                        </a>
                      ) : (
                        <a className="button-box green">
                          <CheckCircleOutlineIcon fontSize="small" />
                          <div className="btn-text">Activated</div>
                        </a>
                      )
                    ) : (
                      <a className="button-box">
                        <ShoppingCartIcon fontSize="small" />
                        <div className="btn-text">$ 89/=</div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="view">
              <div className="pad-20"></div>
              <h1>EDUCT Time Management System</h1>
              <p>
                EDUCT time management system is an automated alarming system for
                manage your daily time consumption. So it may very helpfull to
                many occations. Such as Educational purposes, Labour management
                and Personal time management.
              </p>
              <div className="flex-bar-hor" id="download-section">
                <div
                  className="qr-scr"
                  onClick={() => {
                    if (localStorage.getItem("userKey").length > 0) {
                      var dummy = document.createElement("input"),
                        text = localStorage.getItem("userKey");
                      document.body.appendChild(dummy);
                      dummy.value = text;
                      dummy.select();
                      document.execCommand("copy");
                      document.body.removeChild(dummy);
                      seterr(
                        `Your product key has been copied to your clipboard successfully. Paste any where that you want to use it. You can use a Qr code scanner to scan this qr code too..`
                      );
                      seterrcat("success");
                      seterrstate(true);
                    }
                  }}
                >
                  <div id="canvas"></div>
                  <p className="bold">CLICK HERE TO COPY THE kEY</p>
                </div>
                <h4>Scan QR</h4>
                {download | btns ? null : (
                  <div className="flex-bar-ver">
                    <a className="button-box" href="#download">
                      <BrowserUpdatedOutlinedIcon fontSize="small" />
                      <div className="btn-text">Download App</div>
                    </a>
                  </div>
                )}
                {download ? (
                  <Countdown date={Date.now() + 5000} renderer={renderer} />
                ) : null}
                {btns ? (
                  <div className="flex-bar-ver">
                    <a
                      className="button-box"
                      onClick={() => {
                        downloadApp();
                        down();
                      }}
                    >
                      <BrowserUpdatedOutlinedIcon fontSize="small" />
                      <div className="btn-text">Download</div>
                    </a>
                    <a
                      className="button-box"
                      onClick={() => {
                        var dummy = document.createElement("input"),
                          text = window.location.href;
                        document.body.appendChild(dummy);
                        dummy.value = text;
                        dummy.select();
                        document.execCommand("copy");
                        document.body.removeChild(dummy);
                        seterr(
                          `Sharing address has been copied to your clipboard successfully. Paste any where you want to share it..`
                        );
                        seterrcat("success");
                        setdownload(false);
                        setbtns(false);
                        seterrstate(true);
                      }}
                    >
                      <ShareIcon fontSize="small" />
                      <div className="btn-text">Share Page</div>
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <div className="view">
            <div className="pad-20"></div>
            <h1>Oops!.</h1>
            <p>
              This page content will be only visible to signed members only.
              Please sign in or create an account for get access to the page
              content.
            </p>
            <div className="flex-bar-ver">
              <a href="/signin" className="button-box">
                <LoginOutlinedIcon fontSize="small" />
                <div className="btn-text">Sign In</div>
              </a>
              <a href="/signup" className="button-box">
                <PersonAddAlt1OutlinedIcon fontSize="small" />
                <div className="btn-text">Sign up</div>
              </a>
            </div>
          </div>
        )}

        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Download;
