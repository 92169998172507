import React from "react";
import gr from "./assets/gr.png";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Footer = () => {
  return (
    <div className="footer-bar">
      <a className="go-top" href="#top">
        <KeyboardArrowUpIcon fontSize="large" />
        Go To Top
      </a>
      <div className="flex-bar">
        <a href="/questions" className="trans-btn">
          Questions
        </a>
        <a href="/terms" className="trans-btn">
          Terms
        </a>
        <a href="/privacy_policy" className="trans-btn">
          Privacy
        </a>
        <a href="/support" className="trans-btn">
          Support
        </a>
        <a href="/about" className="trans-btn">
          About
        </a>
      </div>
      <div className="img">
        <img src={gr} alt="" />
      </div>
      <h4>&copy;2022 DSPACE</h4>
      <p>
        By <br /> Dark Leo - Ceylon
      </p>
    </div>
  );
};

export default Footer;
