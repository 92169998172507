import React, { useEffect, useState } from "react";
import MessageBox from "../MessageBox";
import Banner from "../Banner";
import io from "socket.io-client";
import Slide from "@mui/material/Slide";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/wt.png";
import pic from "../assets/ww.gif";
import Footer from "../Footer";
import { v4 as uuidV4 } from "uuid";
import "./school.css";

const Profile = ({ mode }) => {
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [userFunc, setuserFunc] = useState("");
  const [pphoto, setpphoto] = useState("");
  const [sclName, setsclName] = useState("");
  const [sclsName, setsclsName] = useState("");
  const [sclLoc, setsclLoc] = useState("");
  const [arrAdd, setarrAdd] = useState([]);
  const [UrlToUse, setUrlToUse] = useState("");
  const [schoolToken, setschoolToken] = useState("");
  const [UrlToView, setUrlToView] = useState("");
  const [subDoms, setsubDoms] = useState([]);
  const [passAllow, setpassAllow] = useState(false);
  const [password, setpassword] = useState("");
  const [DomainHave, setDomainHave] = useState(false);

  const containerRef = React.useRef(null);

  useEffect(() => {
    mode.emit("subdoms");
  }, []);

  const genSchoolAdd = () => {
    if (sclName.length > 0) {
      if (sclsName.length > 0) {
        if (sclLoc.length > 0) {
          if (!schoolToken) {
            setschoolToken(uuidV4());
          }
        }
      }
    }
  };
  const setSchool = () => {
    const creator = localStorage.getItem("userToken");

    if (pphoto.length > 0) {
      mode.emit("create_school", {
        token: schoolToken,
        creator: creator,
        name: sclName,
        sname: sclsName,
        loc: sclLoc,
        subdom: UrlToUse,
        pass: password,
        photo: pphoto,
      });
    } else {
      var t = document.getElementById("files");
      t.click();
    }
  };

  useEffect(() => {
    mode.on("rec_subdoms", (data) => {
      setsubDoms(data.res);
      data.res.map((val) => {
        if (val.creator === localStorage.getItem("userToken")) {
          localStorage.setItem("userSchool", val.creator);
        }
      });
    });
    mode.on("created_school", (data) => {
      if (data.msg === "created") {
        setsclName("");
        setsclsName("");
        setsclLoc("");
        setuserFunc("");
        setUrlToUse("");
        setschoolToken("");
        setpassword("");
        setpassAllow(false);
        seterrstate(true);
        seterr(
          "You have successfully applied to create a school on Dspace. We will be inform you within 24 Hours when your domain is ready."
        );
        seterrcat("success");
      }
    });
    mode.on("school_have", (data) => {
      if (data.msg === "have") {
        setsclName("");
        setsclsName("");
        setsclLoc("");
        setuserFunc("");
        setUrlToUse("");
        setschoolToken("");
        setpassword("");
        setpassAllow(false);
        seterrstate(true);
        seterr(
          "Your details are perfectly matching to one of our school contacts. Please sign in to that account or create a new school using new details."
        );
        seterrcat("failed");
      }
    });
  }, [mode]);
  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item">
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>D-Schools</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>D-Schools</h1>
          <p>
            Make a web page to your school with Dspace. We have so many
            templates that you can use to build your school profiler. We are
            only allowing one account per one school. So make it creative and
            delightfull. You can add more 3 technical administrators to control
            your web suite. Student's and staff members can search your school
            on Dspace Search and visit it.
          </p>
          <div className="flex-bar-ver">
            <div
              className="button-box"
              onClick={() => {
                setuserFunc("create");
              }}
            >
              <HistoryEduIcon fontSize="small" />
              <div className="btn-text">Create</div>
            </div>
            <div
              className="button-box"
              onClick={() => {
                setuserFunc("sign");
              }}
            >
              <HistoryEduIcon fontSize="small" />
              <div className="btn-text">Sign in</div>
            </div>
            <div
              className="button-box"
              onClick={() => {
                setuserFunc("visit");
              }}
            >
              <HistoryEduIcon fontSize="small" />
              <div className="btn-text">Visit</div>
            </div>
          </div>
        </div>
        {userFunc === "create" ? (
          <div className="view">
            <div className="create-box">
              <div className="box-view"></div>
              <div className="left-view">
                <div className="doc-right">
                  <img
                    src={pphoto.length > 0 ? pphoto : pic}
                    alt=""
                    onClick={() => {
                      var t = document.getElementById("files");
                      t.click();
                    }}
                  />
                  <input
                    hidden
                    type="file"
                    name="files"
                    id="files"
                    onChange={(e) => {
                      if (e.target.files[0].name.length > 0) {
                        let reader = new FileReader();
                        reader.onloadend = function() {
                          setpphoto(reader.result);
                        };
                        reader.readAsDataURL(e.target.files[0]);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="right-sec">
                <h4>Let's Create Your School</h4>
                <div className="pad-20"></div>
                <p>
                  {schoolToken.length > 0
                    ? passAllow
                      ? "Set up new password for your school"
                      : "Set up your web address now!"
                    : "You will be the creator of this school unit"}
                </p>
                {schoolToken.length > 0 ? (
                  passAllow ? (
                    <div className="down-flex">
                      <div className="input-bar">
                        <div className="icon">
                          <HistoryEduIcon fontSize="small" />
                        </div>
                        <input
                          type="password"
                          name="schoolpassword"
                          placeholder="Set Password"
                          id="sclpass"
                          onChange={(e) => {
                            if (e.target.value.length > 8) {
                              setpassword(e.target.value);
                            } else {
                              setpassword("");
                            }
                          }}
                          required
                        />
                      </div>
                      <p>
                        {password.length < 8
                          ? "Password must contain more than 8 characters."
                          : "Password is valid "}
                      </p>
                      <div className="flex-bar-ver">
                        <div
                          className="button-box"
                          onClick={() => {
                            if (password.length > 8) {
                              setSchool();
                            }
                          }}
                        >
                          <div className="btn-text">Next</div>
                          <NavigateNextIcon fontSize="small" />
                        </div>
                        <div
                          className="button-box"
                          onClick={() => {
                            setsclName("");
                            setsclsName("");
                            setsclLoc("");
                            setuserFunc("");
                            setUrlToUse("");
                            setschoolToken("");
                            setpassword("");
                            setpassAllow(false);
                          }}
                        >
                          <div className="btn-text">Close</div>
                          <CloseIcon fontSize="small" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="down-flex">
                      <div className="input-bar">
                        <div className="icon">
                          <HistoryEduIcon fontSize="small" />
                        </div>
                        <input
                          type="text"
                          name="schoolsubdomain"
                          placeholder="Enter URL"
                          id="sclurl"
                          onChange={(e) => {
                            if (e.target.value.length > 0) {
                              subDoms.map((val) => {
                                if (
                                  val.subdom ===
                                  "https://" + e.target.value + ".dspace.lk"
                                ) {
                                  setDomainHave(true);
                                  setUrlToUse(
                                    "https://" + e.target.value + ".dspace.lk"
                                  );
                                } else {
                                  setDomainHave(false);
                                  setUrlToUse(
                                    "https://" + e.target.value + ".dspace.lk"
                                  );
                                }
                              });
                            } else {
                              setUrlToUse("");
                            }
                          }}
                          required
                        />
                      </div>
                      <p className={DomainHave ? "btn-red" : "btn-green"}>
                        {DomainHave ? (
                          <WarningAmberRoundedIcon fontSize="small" />
                        ) : (
                          <CheckCircleOutlineRoundedIcon fontSize="small" />
                        )}
                        {DomainHave
                          ? "This address already taken. Try another."
                          : UrlToUse}
                      </p>
                      {UrlToUse.length > 0 ? (
                        <div className="flex-bar-ver">
                          <div
                            className="button-box"
                            onClick={() => {
                              if (UrlToUse.length > 19) {
                                setpassAllow(true);
                              } else {
                                setpassAllow(false);
                              }
                            }}
                          >
                            <div className="btn-text">Next</div>
                            <NavigateNextIcon fontSize="small" />
                          </div>
                          <div
                            className="button-box"
                            onClick={() => {
                              setsclName("");
                              setsclsName("");
                              setsclLoc("");
                              setuserFunc("");
                              setUrlToUse("");
                              setschoolToken("");
                            }}
                          >
                            <div className="btn-text">Close</div>
                            <CloseIcon fontSize="small" />
                          </div>
                        </div>
                      ) : (
                        <div className="flex-bar-ver">
                          <div
                            className="button-box"
                            onClick={() => {
                              setsclName("");
                              setsclsName("");
                              setsclLoc("");
                              setuserFunc("");
                              setarrAdd([]);
                            }}
                          >
                            <div className="btn-text">Close</div>
                            <CloseIcon fontSize="small" />
                          </div>
                        </div>
                      )}
                      <p>{"Your Token : " + schoolToken}</p>
                    </div>
                  )
                ) : (
                  <div className="down-flex">
                    <div className="input-bar">
                      <div className="icon">
                        <HistoryEduIcon fontSize="small" />
                      </div>
                      <input
                        type="text"
                        name="schoolName"
                        placeholder="School Name"
                        id="sclnme"
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            setsclName(e.target.value);
                          } else {
                            setsclName("");
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="input-bar">
                      <div className="icon">
                        <HistoryEduIcon fontSize="small" />
                      </div>
                      <input
                        type="text"
                        name="schoolshortName"
                        placeholder="School Short Name"
                        id="sclsnme"
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            setsclsName(e.target.value);
                          } else {
                            setsclsName("");
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="input-bar">
                      <div className="icon">
                        <HistoryEduIcon fontSize="small" />
                      </div>
                      <input
                        type="text"
                        name="schoolLoc"
                        placeholder="City"
                        id="sclcity"
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            setsclLoc(e.target.value);
                          } else {
                            setsclLoc("");
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="flex-bar-ver">
                      <div
                        className="button-box"
                        onClick={() => {
                          if (sclName.length > 0) {
                            if (sclsName.length > 0) {
                              if (sclLoc.length > 0) {
                                genSchoolAdd();
                              }
                            }
                          }
                        }}
                      >
                        <div className="btn-text">Next</div>
                        <NavigateNextIcon fontSize="small" />
                      </div>
                      <div
                        className="button-box"
                        onClick={() => {
                          setsclName("");
                          setsclsName("");
                          setsclLoc("");
                          setuserFunc("");
                        }}
                      >
                        <div className="btn-text">Close</div>
                        <CloseIcon fontSize="small" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : userFunc === "sign" ? (
          <div className="view">
            <div className="create-box">
              <div className="box-view"></div>
              <div className="left-view">
                <div className="doc-right">
                  <img
                    src={
                      UrlToView.length > 0
                        ? subDoms.map((val) => {
                            if (UrlToView === val.subdom) {
                              return val.photo;
                            }
                          })
                        : pic
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="right-sec">
                <h4>School Sign In</h4>
                <div className="pad-20"></div>
                <p>Please select your school</p>
                <div className="fown-flex">
                  <select
                    name="select"
                    id="select-category"
                    onChange={(e) => {
                      setUrlToView(e.target.value);
                    }}
                  >
                    <option hidden>Select a school</option>
                    {subDoms.map((val) => {
                      return (
                        <option value={val.subdom} key={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                  {UrlToView.length > 0 ? (
                    <div className="flex-bar-ver">
                      <div
                        className="button-box"
                        onClick={() => {
                          window.location.href =
                            UrlToView +
                            "/sign_in?token=" +
                            localStorage.getItem("userSchool");
                        }}
                      >
                        <div className="btn-text">Sign In</div>
                        <HistoryEduIcon fontSize="small" />
                      </div>
                      <div
                        className="button-box"
                        onClick={() => {
                          setUrlToView("");
                          setuserFunc("");
                        }}
                      >
                        <div className="btn-text">Cancel</div>
                        <CloseIcon fontSize="small" />
                      </div>
                    </div>
                  ) : (
                    <div className="flex-bar-ver">
                      <div
                        className="button-box"
                        onClick={() => {
                          setUrlToView("");
                          setuserFunc("");
                        }}
                      >
                        <div className="btn-text">Cancel</div>
                        <CloseIcon fontSize="small" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : userFunc === "visit" ? (
          <div className="view">
            <div className="create-box">
              <div className="box-view"></div>
              <div className="left-view">
                <div className="doc-right">
                  <img
                    src={
                      UrlToView.length > 0
                        ? subDoms.map((val) => {
                            if (UrlToView === val.subdom) {
                              return val.photo;
                            }
                          })
                        : pic
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="right-sec">
                <h4>Visit A School Page</h4>
                <div className="pad-20"></div>
                <p>Please select the school you want to visit.</p>
                <div className="fown-flex">
                  <select
                    name="select"
                    id="select-category"
                    onChange={(e) => {
                      setUrlToView(e.target.value);
                    }}
                  >
                    <option hidden>Select a school</option>
                    {subDoms.map((val) => {
                      return (
                        <option value={val.subdom} key={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                  {UrlToView.length > 0 ? (
                    <div className="flex-bar-ver">
                      <div
                        className="button-box"
                        onClick={() => {
                          window.location.href = UrlToView + "/visit";
                        }}
                      >
                        <div className="btn-text">Visit School</div>
                        <HistoryEduIcon fontSize="small" />
                      </div>
                      <div
                        className="button-box"
                        onClick={() => {
                          setUrlToView("");
                          setuserFunc("");
                        }}
                      >
                        <div className="btn-text">Cancel</div>
                        <CloseIcon fontSize="small" />
                      </div>
                    </div>
                  ) : (
                    <div className="flex-bar-ver">
                      <div
                        className="button-box"
                        onClick={() => {
                          setUrlToView("");
                          setuserFunc("");
                        }}
                      >
                        <div className="btn-text">Cancel</div>
                        <CloseIcon fontSize="small" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Profile;
