import React, { useRef } from "react";
import Footer from "./Footer";
import Banner from "./Banner";
import logo from "../wt.png";
import pic from "./assets/er.png";
import Slide from "@mui/material/Slide";

const UserGuide = () => {
  const containerRef = React.useRef(null);
  return (
    <div className="scrl">
      <div className="post">
        <Banner />
        <div className="item">
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>EDUCT SYSTEM</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>User Guidance</h1>
          <p>Hi, User! </p>
          <p>
            Thank you for downloading and installing our product on your PC.
            Unfortunately we are only providing our system for Windows&trade;
            PC's. Mac&trade; users may want to stay a bit of time to configure
            our system for you.
          </p>
          <p>
            Please read these user guide very carefully. This page contains all
            the details and featurs of Educt time management system.
          </p>
          <div className="divider-dash"></div>
          <h4>Minimum Requirements</h4>
          <p>
            You PC should be:
            <br />
            <br />
            <ul>
              <li>Running on any Windows&trade; system.</li>
              <li>Greater than 2 gb free of hard space.</li>
              <li>Support to latest .Net frameworks.</li>
              <li>Running on more than 4GB of Memory (RAM). </li>
              <li>Running on minimum of 128MB graphics. </li>
              <li>Have an active internet connection for activation.</li>
              <li>Running on minimum P4 processor.</li>
            </ul>
          </p>
          <div className="divider-dash"></div>
          <h4>Download And Install</h4>
          <p>
            <ul>
              <li>Dowload the program to your desktop PC.</li>
            </ul>
            <br />
            <br />
            Please goto this address to download Educt System :{" "}
            <a href="https://www.dspace.lk/download">Click here to download</a>
          </p>
          <p>
            Installation :
            <br />
            <br />
            <ul>
              <li>
                Press next on the flash window to start installation process.
              </li>
              <li>
                Press next on secondly appearing window to continue process.
              </li>
              <li>
                Choose the user account that you want to install this program.
              </li>
              <li>
                Click allow on the pop-up window appearing on your screen.
              </li>
              <li>
                Tada! Installation is complete. Close window to finish
                installation process.
              </li>
            </ul>
          </p>
          <div className="divider-dash"></div>
          <h4>Features</h4>
          <p>
            Our program is :
            <br />
            <br />
            <ul>
              <li>
                Light weight program. It is compatible to run on any
                Windows&trade; version.
              </li>
              <li>
                More user friendly. All our controls and functions allow users
                to get their maximum expiriense.
              </li>
              <li>
                Easy to handle. Fully automated time scheduling with manual
                adjustment features. So you can handle it safely.
              </li>
              <li>
                Runnig on both online and offline modes. But you should have an
                active internet connection to Activation process.
              </li>
              <li>
                Back ups your data. You can just sync your system with our
                online database to maintain your program updated.
              </li>
            </ul>
          </p>
          <div className="divider-dash"></div>
          <h4>Reported Bugs </h4>
          <p>
            I made this system to give you the maximum free chance. you can set
            up so many accounts on this platform and get so many one month trial
            periods.
            <br />
            <br />
            It's your choise to feed us or not. I don't hate anyone that use
            this program illeagally. Because they have a family too.
          </p>
        </div>

        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserGuide;
