import React, { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import MailLockTwoToneIcon from "@mui/icons-material/MailLockTwoTone";

const Banner = () => {
  const signed = localStorage.getItem("userSign");
  const varify = localStorage.getItem("userVarify");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lst = urlParams.get("text");

  useEffect(() => {
    if (lst) {
      var t = document.getElementById("search");
      t.value = lst;
    }
  }, []);

  return (
    <>
      {signed === "true" ? (
        varify === "no" ? (
          <div className="banner" id="top">
            <h4>Varify Your Email Now!</h4>
            <p> Let's varify the email address that you provided.</p>
            <div className="flex-bar-ver">
              <a href="/varify_email" className="button-box">
                <MailLockTwoToneIcon fontSize="small" />
                <div className="btn-text">Varify Now</div>
              </a>
            </div>
          </div>
        ) : null
      ) : varify === "no" ? (
        <div className="banner" id="top">
          <h4>Sign In!</h4>
          <p> Let's create an account for you...</p>
          <div className="flex-bar-ver">
            <a href="/signin" className="button-box">
              <LoginOutlinedIcon fontSize="small" />
              <div className="btn-text">Sign In</div>
            </a>
            <a href="/signup" className="button-box">
              <PersonAddAlt1OutlinedIcon fontSize="small" />
              <div className="btn-text">Sign up</div>
            </a>
          </div>
        </div>
      ) : (
        <div className="banner" id="top">
          <h4>Sign In!</h4>
          <p> Let's create an account for you...</p>
          <div className="flex-bar-ver">
            <a href="/signin" className="button-box">
              <LoginOutlinedIcon fontSize="small" />
              <div className="btn-text">Sign In</div>
            </a>
            <a href="/signup" className="button-box">
              <PersonAddAlt1OutlinedIcon fontSize="small" />
              <div className="btn-text">Sign up</div>
            </a>
          </div>
        </div>
      )}
      <div className="banner" id="top">
        <div className="input-bar">
          <div className="icon">
            <SearchIcon fontSize="small" />
          </div>
          <input
            type="text"
            name="search"
            placeholder="Search Space"
            id="search"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (e.target.value.length > 0) {
                  window.location.href = "/search?text=" + e.target.value;
                }
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Banner;
