import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../er.png";
import gr from "./assets/gr.png";
import pic from "./assets/ww.gif";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import LockResetIcon from "@mui/icons-material/LockReset";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import MessageBox from "./MessageBox";
import moment from "moment";
var CryptoJS = require("crypto-js");

const Signin = ({ mode }) => {
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const signin = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.match(mailformat)) {
      if (password.length >= 6) {
        mode.emit("sign", {
          token: localStorage.getItem("userToken"),
          email: email,
          password: password,
        });
      } else {
        seterr(
          "Your password must be more than 6 characters. Please insert correct password!."
        );
        seterrcat("failed");
        seterrstate(true);
      }
    } else {
      seterr("Your email address might be invalid. Please correct it first!.");
      seterrcat("failed");
      seterrstate(true);
    }
  };

  useEffect(() => {
    mode.on("signed_ok", (data) => {
      if (data.msg) {
        seterr(
          "Your email does not match to the password you entered. Please insert correct log in data!."
        );
        seterrcat("failed");
        seterrstate(true);
      } else {
        var bytes = CryptoJS.AES.decrypt(
          data.res,
          localStorage.getItem("userToken")
        );
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        [decryptedData].map((val) => {
          localStorage.setItem(
            "userLoggedAt",
            moment().format("YYYYMMDD, h:mm a")
          );
          localStorage.setItem("userToken", val.device_id);
          localStorage.setItem("userSign", true);
          seterr("Welcome! You have successfully signed in to Dspace.");
          seterrcat("success");
          seterrstate(true);
          return (window.location.href = "/home");
        });
      }
    });
  }, [mode]);

  return (
    <div className="signin">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="form">
        <div className="form-icon">
          <img
            src={logo}
            alt=""
            onClick={() => {
              window.location.href = "/home";
            }}
          />
        </div>
        <div className="form-pic">
          <img src={pic} alt="" />
        </div>
        <div className="form-title">Sign in</div>
        <div className="form-input">
          <div className="input-bar">
            <div className="icon">
              <PersonIcon fontSize="small" />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Username"
              id="email"
              onKeyUp={(e) => {
                if (e.target.value.length > 0) {
                  setemail(e.target.value);
                }
              }}
              required
            />
          </div>
          <div className="input-bar">
            <div className="icon">
              <PasswordIcon fontSize="small" />
            </div>
            <input
              type="password"
              className="pass"
              name="password"
              placeholder="Password"
              id="password"
              onKeyUp={(e) => {
                if (e.target.value.length >= 0) {
                  setpassword(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="form-link">
          <a href="/forgot_password">
            <LockResetIcon fontSize="small" />
            &nbsp;Forgot Password
          </a>
        </div>

        <div className="form-button">
          <div
            className="button-box"
            onClick={() => {
              signin();
            }}
          >
            <LoginOutlinedIcon fontSize="small" />
            <div className="btn-text">Sign In</div>
          </div>
          <a href="/signup" className="button-box">
            <PersonAddAlt1OutlinedIcon fontSize="small" />
            <div className="btn-text">Sign up</div>
          </a>
        </div>

        <div className="form-bottom">
          <div className="footer-bar">
            <div className="flex-bar">
              <a href="/home" className="trans-btn">
                Home
              </a>
              <a href="/download" className="trans-btn">
                Download
              </a>
              <a href="/space" className="trans-btn">
                Space
              </a>
              <a href="/support" className="trans-btn">
                Support
              </a>
              <a href="/about" className="trans-btn">
                About
              </a>
            </div>
            <div className="img">
              <img src={gr} alt="" />
            </div>
            <h4>&copy;2022 DSPACE</h4>
            <p>
              By <br /> Dark Leo - Ceylon
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
