import React, { useState, useEffect, useRef } from "react";
import logo from "../wt.png";
import pic from "./assets/ww.gif";
import er from "./assets/er.png";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Banner from "./Banner";
import Footer from "./Footer";
import MessageBox from "./MessageBox";
import axios from "axios";
import Slide from "@mui/material/Slide";

const Support = ({ mode }) => {
  const [begin, setbegin] = useState(true);
  const [email, setemail] = useState("");
  const [category, setcategory] = useState("");
  const [detail, setdetail] = useState("");
  const [file, setfile] = useState("");
  const [filename, setfilename] = useState("");

  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");

  const containerRef = React.useRef(null);

  const [subs, setsubs] = useState([]);

  const submitSupport = () => {
    if (email.length > 0) {
      if (category.length > 0) {
        if (detail.length > 0) {
          mode.emit("submit_issues", {
            email: email,
            file: file ? file : "no",
            issue: detail,
            device_id: localStorage.getItem("userToken"),
            category: category,
          });
        }
      }
    }
  };
  const deleteSub = (val) => {
    mode.emit("delete_submission", {
      id: val,
      device_id: localStorage.getItem("userToken"),
    });
  };
  useEffect(() => {
    mode.emit("get_sumbissions", {
      device_id: localStorage.getItem("userToken"),
    });
  }, [begin]);

  useEffect(() => {
    mode.on("recieve_sumbissions", (data) => {
      if (data.res) {
        setsubs(data.res);
        setbegin(false);
      }
    });
    mode.on("deleted_sumbission", (data) => {
      if (data.res) {
        seterr(
          "Your submission has been successfully deleted. Hope you may not need it anymore."
        );
        seterrcat("success");
        seterrstate(true);
        mode.emit("get_sumbissions", {
          device_id: localStorage.getItem("userToken"),
        });
      }
    });
    mode.on("submitted_rep", (data) => {
      if (data.res) {
        seterr(
          "Your support request has been successfully submitted. Please check your mail box after 24 Hours."
        );
        seterrcat("success");
        seterrstate(true);
        setemail("");
        setcategory("");
        setdetail("");
        setfile([]);
        setfilename("");
        mode.emit("get_sumbissions", {
          device_id: localStorage.getItem("userToken"),
        });
        var x = document.getElementById("select-category");
        x.value = "";
      }
    });
  }, [mode]);

  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item">
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>DSPACE</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>Get Support</h1>
          <p>Please select the category that you want to get supported.</p>

          <div className="flex-bar-hor">
            <select
              name="select category"
              id="select-category"
              onChange={(e) => {
                setcategory(e.target.value);
              }}
            >
              <option value="" hidden>
                Select Category
              </option>
              <option value="educt">EDUCT TMS</option>
              <option value="billing">BILLING</option>
              <option value="technical">TECHNICAL</option>
              <option value="online">ONLINE</option>
              <option value="business">BUSINESS</option>
            </select>
            {category.length > 0 ? (
              <div className="input-bar">
                <div className="icon">
                  <AlternateEmailIcon fontSize="small" />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  id="email"
                  onKeyUp={(e) => {
                    if (e.target.value.length > 0) {
                      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      if (e.target.value.match(mailformat)) {
                        setemail(e.target.value);
                      } else {
                        setemail("");
                      }
                    }
                  }}
                  required
                />
              </div>
            ) : null}
            {email.length > 0 ? (
              <div className="input-bar">
                <textarea
                  id="txtarea"
                  className="textarea"
                  placeholder="How do you want our support?"
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      setdetail(e.target.value);
                    } else {
                      setdetail("");
                    }
                  }}
                ></textarea>
              </div>
            ) : null}
            {detail.length > 3 ? (
              <div className="flex-bar-hor">
                <input
                  type="file"
                  name="file"
                  placeholder="Select Attachments"
                  id="file"
                  accept="image/jpeg,image/png,application/pdf"
                  hidden
                  onChange={(e) => {
                    if (e.target.files[0] !== file) {
                      setfilename(e.target.files[0].name);
                      let reader = new FileReader();
                      reader.onloadend = function() {
                        setfile(reader.result);
                      };
                      reader.readAsDataURL(e.target.files[0]);
                    }
                  }}
                  required
                />
                <p
                  className="attach"
                  onClick={() => {
                    var x = document.getElementById("file");
                    x.click();
                  }}
                >
                  <AttachmentIcon fontSize="small" />
                  &nbsp;
                  {file
                    ? filename.length < 40
                      ? filename
                      : filename.substring(0, 40) + "..."
                    : "Select An Attachment"}
                </p>
              </div>
            ) : null}
          </div>
          {detail.length > 3 ? (
            <div className="flex-bar-ver">
              <div
                className="button-box"
                onClick={() => {
                  submitSupport();
                }}
              >
                <LocalPostOfficeIcon fontSize="small" />
                <div className="btn-text">Submit</div>
              </div>
              <div
                className="button-box"
                onClick={() => {
                  var x = document.getElementById("select-category");
                  x.value = "";
                  setemail("");
                  setdetail("");
                  setcategory("");
                }}
              >
                <CancelIcon fontSize="small" />
                <div className="btn-text">Cancel</div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="view">
          <h4>Your Submissions</h4>
          <p>List of your previous submissions will appear here..</p>
          <div className="divider-dash"></div>
          {subs.length > 0 ? (
            subs.map((valsub) => {
              return (
                <div className="submittion-card" key={valsub.id}>
                  <div className="card-det">
                    <h4>
                      <div className="card-flex">
                        <img src={er} alt="" />
                        {valsub.category}
                      </div>
                      <div className="card-flex">
                        <a href={valsub.file} download className="tr-btn">
                          <AttachmentIcon fontSize="small" />
                          Attachment
                        </a>
                        <div
                          className="tr-btn"
                          onClick={() => {
                            deleteSub(valsub.id);
                          }}
                        >
                          <DeleteRoundedIcon fontSize="small" />
                          Delete
                        </div>
                      </div>
                    </h4>
                    <p className="detail">{valsub.issue}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No submissions found!</p>
          )}
        </div>
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Support;
