import React, { useState } from "react";
import Footer from "./Footer";
import MessageBox from "./MessageBox";
import Banner from "./Banner";
import logo from "../wt.png";
import pic from "./assets/ww.gif";
import Slide from "@mui/material/Slide";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ThumbUpTwoToneIcon from "@mui/icons-material/ThumbUpTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";

const SearchSpace = ({ posts = [] }) => {
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lst = urlParams.get("text");
  const post_arr = posts;
  const containerRef = React.useRef(null);

  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item" ref={containerRef}>
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>SEARCH</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>Search Results</h1>
          <p>{lst}</p>
          <div className="divider-dash"></div>
          <div className="flex-wrap-bar">
            {post_arr.map((valpost) => {
              if (
                valpost.title.toLowerCase().includes(lst.toLowerCase()) ||
                valpost.description.toLowerCase().includes(lst.toLowerCase())
              ) {
                return (
                  <div
                    className="wrap-card"
                    key={valpost.id}
                    onClick={() => {
                      window.location.href =
                        "/read?post_id=" + valpost.share_id;
                    }}
                  >
                    <div className="image">
                      <img src={valpost.media} alt="" />
                    </div>
                    <div className="title">
                      <h4>
                        <NewspaperIcon fontSize="small" />
                        &nbsp;{valpost.title}
                      </h4>
                    </div>
                    <div className="bot">
                      <h4>
                        <RemoveRedEyeRoundedIcon fontSize="small" />
                        &nbsp;{valpost.views}
                        &nbsp; &nbsp; &nbsp;
                        <ThumbUpTwoToneIcon fontSize="small" />
                        &nbsp;{valpost.likes}
                        &nbsp; &nbsp; &nbsp;
                        <QuestionAnswerTwoToneIcon fontSize="small" />
                        &nbsp;{valpost.comments}
                      </h4>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div
            className="button-box"
            onClick={() => {
              window.history.back();
            }}
          >
            <KeyboardArrowLeftIcon fontSize="small" />
            <div className="btn-text">Go Back</div>
          </div>
        </div>

        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SearchSpace;
