import React from "react";
import logo from "../er.png";
import Countdown from "react-countdown";
import CancelIcon from "@mui/icons-material/Cancel";

const MessageBox = ({ state, setstate, message, category }) => {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setstate(false);
    } else {
      // Render a countdown
      return (
        <div
          className="button-box"
          onClick={() => {
            setstate(false);
          }}
        >
          <CancelIcon fontSize="small" />

          <div className="btn-text">Close ({seconds})</div>
        </div>
      );
    }
  };

  return (
    <div className="msg-box" style={state ? { top: "0px" } : { top: "-100vh" }}>
      <div className="message">
        <img src={logo} alt="" />
        {category === "success" ? (
          <h4 className="success">Success!</h4>
        ) : (
          <h4 className="failed">Oops!</h4>
        )}
        <p>{message}</p>
        <div className="flex-bar-ver">
          {state ? (
            <Countdown date={Date.now() + 5000} renderer={renderer} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
