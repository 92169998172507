import React, { useState, useRef, useEffect } from "react";
import logo from "../wt.png";
import pic from "./assets/ww.gif";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ThumbUpTwoToneIcon from "@mui/icons-material/ThumbUpTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";
import Banner from "./Banner";
import Footer from "./Footer";
import MessageBox from "./MessageBox";
import axios from "axios";
import Slide from "@mui/material/Slide";

const Space = ({ mode, posts = [] }) => {
  const [subsEmail, setsubsEmail] = useState("");
  const [showEmailbar, setshowEmailbar] = useState(false);
  const post_arr = posts;

  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");

  const containerRef = React.useRef(null);

  const subscribeNow = () => {
    if (subsEmail.length > 0) {
      mode.emit("subscribe", {
        email: subsEmail,
        user_id: localStorage.getItem("userToken"),
      });
    } else {
      setshowEmailbar(false);
    }
  };
  const unsubscribeNow = () => {
    mode.emit("unsubscribe", {
      user_id: localStorage.getItem("userToken"),
    });
  };

  useEffect(() => {
    mode.on("subscribed", (data) => {
      if (data.msg === "subscribed") {
        seterr(
          "Your subscription request has been successfully granted. You are now subscribed to Dspace News Letter."
        );
        seterrcat("success");
        localStorage.setItem("userSubscription", "yes");
        seterrstate(true);
        setshowEmailbar(false);
      }
    });
    mode.on("unsubscribed", (data) => {
      if (data.msg === "unsubscribed") {
        seterr(
          "Your subscription has been successfully deleted. You are now Unsubscribed from Dspace News Letter."
        );
        seterrcat("success");
        seterrstate(true);
        localStorage.setItem("userSubscription", "no");
      }
    });
  }, [mode]);
  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="post">
        <Banner />
        <div className="item">
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>SPACE</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>Subscribe To Stay Connected</h1>
          <p>
            {showEmailbar
              ? `Please enter your correct email address for subscribe.`
              : `Keep in touch with Dspace by clicking on subscribe button.`}
          </p>
          {showEmailbar ? (
            <div className="input-bar">
              <div className="icon">
                <AlternateEmailIcon fontSize="small" />
              </div>
              <input
                type="email"
                required
                name="email"
                placeholder="Email"
                id="email"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                    if (e.target.value.match(mailformat)) {
                      setsubsEmail(e.target.value);
                    } else {
                      setsubsEmail("");
                    }
                  }
                }}
              />
            </div>
          ) : null}
          <div className="flex-bar">
            {localStorage.getItem("userSubscription") === "no" ? (
              <div
                className="button-box"
                onClick={() => {
                  if (showEmailbar) {
                    subscribeNow();
                  } else {
                    setshowEmailbar(true);
                  }
                }}
              >
                <NewspaperIcon fontSize="small" />
                <div className="btn-text">Subscribe</div>
              </div>
            ) : (
              <div
                className="button-box"
                onClick={() => {
                  unsubscribeNow();
                }}
              >
                <NewspaperIcon fontSize="small" />
                <div className="btn-text">Unsubscribe</div>
              </div>
            )}
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1>Latest News</h1>
          {post_arr.length > 0 ? (
            <p>Click to read the full article</p>
          ) : (
            <div className="flex-bar-hor">
              <div className="flex-bar-ver">
                <RefreshIcon fontSize="small" className="fa-spin" />
                <p>
                  <a href="/news">Reload</a>
                </p>
              </div>
            </div>
          )}
          <div className="flex-wrap-bar">
            {post_arr.map((valpost) => {
              return (
                <div
                  className="wrap-card"
                  key={valpost.id}
                  onClick={() => {
                    window.location.href = "/read?post_id=" + valpost.share_id;
                  }}
                >
                  <div className="image">
                    <img src={valpost.media} alt="" />
                  </div>
                  <div className="title">
                    <h4>
                      <NewspaperIcon fontSize="small" />
                      &nbsp;{valpost.title}
                    </h4>
                  </div>
                  <div className="bot">
                    <h4>
                      <RemoveRedEyeRoundedIcon fontSize="small" />
                      &nbsp;{valpost.views}
                      &nbsp; &nbsp; &nbsp;
                      <ThumbUpTwoToneIcon fontSize="small" />
                      &nbsp;{valpost.likes}
                      &nbsp; &nbsp; &nbsp;
                      <QuestionAnswerTwoToneIcon fontSize="small" />
                      &nbsp;{valpost.comments}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Space;
