import React, { useEffect, useState } from "react";
import logo from "../er.png";
import gr from "./assets/gr.png";
import pic from "./assets/ww.gif";
import Countdown from "react-countdown";
import HourglassBottomTwoToneIcon from "@mui/icons-material/HourglassBottomTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import PasswordIcon from "@mui/icons-material/Password";
import MailLockTwoToneIcon from "@mui/icons-material/MailLockTwoTone";
import PersonIcon from "@mui/icons-material/Person";
import LockResetIcon from "@mui/icons-material/LockReset";
import MessageBox from "./MessageBox";
var randomString = require("random-string");

const Forgot = ({ mode }) => {
  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [rePassword, setrePassword] = useState("");
  const [code, setcode] = useState("");
  const [send, setsend] = useState(false);
  const [sent, setsent] = useState(false);
  const [passacc, setpassacc] = useState(false);
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const sendCode = () => {
    if (email.length > 0) {
      if (email.match(mailformat)) {
        checkEmailaddress();
      } else {
        seterr(
          "Please insert your correct email address to start the varification proccess!"
        );
        seterrcat("failed");
        seterrstate(true);
      }
    } else {
      seterr(
        "You have not set your email address to start the varification proccess."
      );
      seterrcat("failed");
      seterrstate(true);
    }
  };
  const checkEmailaddress = () => {
    mode.emit("check_Email", { email: email });
  };
  const varifyCode = () => {
    mode.emit("varify_code", {
      vcode: code,
      device_id: localStorage.getItem("userToken"),
    });
  };
  const resender = (val) => {
    var ranD = randomString({
      length: 6,
      numeric: true,
      letters: false,
      special: false,
      exclude: ["6", "4", "1"],
    });
    mode.emit("send_mailto", {
      email: val,
      varify: ranD,
      user:
        localStorage.getItem("userName").length > 0
          ? localStorage.getItem("userName")
          : "User",
      device_id: localStorage.getItem("userToken"),
    });
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setsent(false);
    } else {
      // Render a countdown
      return (
        <p>
          Wait&nbsp;
          {minutes}:{seconds > 9 ? `${seconds}` : `0${seconds}`}
          &nbsp;seconds
        </p>
      );
    }
  };
  const setPassword = () => {
    if (password.length >= 6) {
      if (password === rePassword) {
        mode.emit("update_password", {
          password: password,
          device_id: localStorage.getItem("userToken"),
        });
      } else {
        seterr(
          `Your tow passwords were unmatched. Please re-type your password.`
        );
        seterrcat("failed");
        seterrstate(true);
      }
    }
  };

  useEffect(() => {
    mode.on("checked", (data) => {
      if (data.msg === "valid") {
        resender(data.email);
        localStorage.setItem("userToken", data.id);
      } else {
        console.log("not valid");
      }
    });
    mode.on("sent", (data) => {
      if (data.msg === "sent") {
        seterr(
          `We have successfully sent the new varification code to your email address : ${localStorage.getItem(
            "userEmail"
          )}.`
        );
        seterrcat("success");
        seterrstate(true);
        setsend(true);
        setsent(true);
      }
    });
    mode.on("varified", (data) => {
      if (data.msg === "matched") {
        seterr(
          `Your account has been varified successfully. Please enter your new password.`
        );
        seterrcat("success");
        seterrstate(true);
        setpassacc(true);
      } else {
        seterr(
          `Your account has not been varified by our system. Please contact support.`
        );
        seterrcat("failed");
        seterrstate(true);
        setpassacc(false);
      }
    });
    mode.on("password_updated", (data) => {
      if (data.msg === "updated") {
        seterr(
          `Your password has been successfully reset. Please log in with your new password.`
        );
        seterrcat("success");
        seterrstate(true);
        setpassacc(false);
        setsend(false);
        setsent(false);
        window.location.href = "/signin";
      }
    });
  }, [mode]);

  return (
    <div className="signin">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <div className="form">
        <div className="form-icon">
          <img
            src={logo}
            alt=""
            onClick={() => {
              window.location.href = "/home";
            }}
          />
        </div>
        <div className="form-pic">
          <img src={pic} alt="" />
        </div>
        <div className="form-title">Reset Password</div>
        <div className="flex-bar-ver">
          {send ? (
            passacc ? (
              <p>Please enter your new password.</p>
            ) : (
              <p>Please enter your varification code</p>
            )
          ) : (
            <p>Enter your valid email address to send varification code</p>
          )}
        </div>
        {passacc ? (
          <div className="form-input">
            <div className="input-bar">
              <div className="icon">
                <PasswordIcon fontSize="small" />
              </div>
              <input
                type="password"
                className="pass"
                name="password"
                placeholder="Password"
                id="password"
                onKeyUp={(e) => {
                  if (e.target.value.length >= 6) {
                    setrePassword(e.target.value);
                  }
                }}
                required
              />
            </div>
          </div>
        ) : (
          <div className="form-input">
            {send ? (
              <div className="input-bar">
                <input
                  type="text"
                  name="varify"
                  id="varify"
                  style={{
                    textAlign: "center",
                    letterSpacing: "10px",
                    paddingLeft: "0",
                  }}
                  onChange={(e) => {
                    if (e.target.value.length === 6) {
                      setcode(e.target.value);
                    } else {
                      setcode("");
                    }
                  }}
                />
              </div>
            ) : (
              <div className="input-bar">
                <div className="icon">
                  <PersonIcon fontSize="small" />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  id="email"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setemail(e.target.value);
                    }
                  }}
                  required
                />
              </div>
            )}
          </div>
        )}
        <div className="form-input">
          {passacc ? (
            <div className="input-bar">
              <div className="icon">
                <PasswordIcon fontSize="small" />
              </div>
              <input
                type="password"
                className="pass"
                name="confirm password"
                placeholder="Confirm Password"
                id="confirmpassword"
                onKeyUp={(e) => {
                  if (e.target.value.length > 0) {
                    setpassword(e.target.value);
                  } else {
                    setpassword("");
                  }
                }}
                required
              />
            </div>
          ) : null}
        </div>
        <div className="form-link">
          {send ? (
            <a
              onClick={() => {
                setsend(false);
              }}
            >
              Go Back
            </a>
          ) : (
            <a
              onClick={() => {
                setsend(true);
              }}
            >
              I Have Code
            </a>
          )}
        </div>
        {send ? (
          passacc ? (
            <div className="form-button">
              <a
                className="button-box"
                onClick={() => {
                  setPassword();
                }}
              >
                <LockResetIcon fontSize="small" />
                <div className="btn-text">Set Password</div>
              </a>
            </div>
          ) : (
            <div className="form-button">
              <a
                className="button-box"
                onClick={() => {
                  varifyCode();
                }}
              >
                <MailLockTwoToneIcon fontSize="small" />
                <div className="btn-text">Varify Email</div>
              </a>
            </div>
          )
        ) : (
          <div className="form-button">
            <a
              className="button-box"
              onClick={() => {
                if (sent) {
                  seterr(
                    `Please wait 2 minutes after sending the code once. Too many attempts may cause to lock your profile for some period.`
                  );
                  seterrcat("failed");
                  seterrstate(true);
                } else {
                  sendCode();
                }
              }}
            >
              <SendTwoToneIcon fontSize="small" />
              <div className="btn-text">Send Code</div>
            </a>
          </div>
        )}
        {sent ? (
          <div className="form-link">
            <a className="button-box">
              <HourglassBottomTwoToneIcon fontSize="small" />
              <div className="btn-text">
                <Countdown date={Date.now() + 119000} renderer={renderer} />
              </div>
            </a>
          </div>
        ) : null}
        <div className="form-bottom">
          <div className="footer-bar">
            <div className="flex-bar">
              <a href="/home" className="trans-btn">
                Home
              </a>
              <a href="/download" className="trans-btn">
                Download
              </a>
              <a href="/space" className="trans-btn">
                Space
              </a>
              <a href="/support" className="trans-btn">
                Support
              </a>
              <a href="/about" className="trans-btn">
                About
              </a>
            </div>
            <div className="img">
              <img src={gr} alt="" />
            </div>
            <h4>&copy;2022 DSPACE</h4>
            <p>
              By <br /> Dark Leo - Ceylon
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
