import React, { useState, useRef, useEffect } from "react";
import logo from "../wt.png";
import pic from "./assets/ww.gif";
import logord from "./assets/er.png";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import Banner from "./Banner";
import Footer from "./Footer";
import Slide from "@mui/material/Slide";
import MessageBox from "./MessageBox";
import axios from "axios";

const Home = ({ mode, ampm }) => {
  const [subsEmail, setsubsEmail] = useState("");
  const [showEmailbar, setshowEmailbar] = useState(false);

  const [errstate, seterrstate] = useState(false);
  const [err, seterr] = useState("");
  const [errcat, seterrcat] = useState("");

  const containerRef = React.useRef(null);

  const subscribeNow = () => {
    if (subsEmail.length > 0) {
      mode.emit("subscribe", {
        email: subsEmail,
        user_id: localStorage.getItem("userToken"),
      });
    } else {
      setshowEmailbar(false);
    }
  };
  const unsubscribeNow = () => {
    mode.emit("unsubscribe", {
      user_id: localStorage.getItem("userToken"),
    });
  };

  useEffect(() => {
    mode.on("subscribed", (data) => {
      if (data.msg === "subscribed") {
        seterr(
          "Your subscription request has been successfully granted. You are now subscribed to Dspace News Letter."
        );
        seterrcat("success");
        localStorage.setItem("userSubscription", "yes");
        seterrstate(true);
        setshowEmailbar(false);
      }
    });
    mode.on("unsubscribed", (data) => {
      if (data.msg === "unsubscribed") {
        seterr(
          "Your subscription has been successfully deleted. You are now Unsubscribed from Dspace News Letter."
        );
        seterrcat("success");
        seterrstate(true);
        localStorage.setItem("userSubscription", "no");
      }
    });
  }, [mode]);

  return (
    <div className="scrl">
      <MessageBox
        state={errstate}
        setstate={seterrstate}
        category={errcat}
        message={err}
      />
      <Banner />
      <div className="post">
        <div className="item" ref={containerRef}>
          <div className="back"></div>
          <img src={logo} className="fa-spin" alt="" />
          <Slide
            direction="up"
            in
            timeout={1000}
            container={containerRef.current}
          >
            <h1>Welcome!</h1>
          </Slide>
          <div id="dec-cir1">
            <img src={pic} alt="" />
          </div>
        </div>
      </div>
      <div className="post">
        <div className="view">
          <div className="pad-20"></div>
          <h1>{ampm}</h1>
          <h4>Welcome to DSPACE&trade;</h4>
          <p>
            I'm Dark Leo. From Ceylon ( Sri Lanka ). I made this platform to
            give you an amazing web expirience. Hope you may enjoy it!.
          </p>
          <p>
            Dspace is a web site, a portal and a platform, that anybody can
            enter and get our services across the world. This web site contains
            Dark Leo's latest productions and creations. All this web site is
            free for every user. No any hidden costs added or promoted. All this
            web space is user friendly and always our users can give their
            feedback to us.
          </p>
          <p>
            This web site is 100% ad-free. There will be no adverticements while
            any kind of downloading process or navigational process. But we are
            highly recommend you to join with our ad-campaign to win bigger
            prizes. All our security details are on our About page.
          </p>
          <div className="flex-bar-ver">
            <a className="button-box" href="#create-ad">
              <TipsAndUpdatesIcon fontSize="small" />
              <div className="btn-text">Create Campaign</div>
            </a>
          </div>
        </div>
        <div className="view">
          <div className="pad-20"></div>
          <h1 id="create-ad">Create Campaign</h1>
          <h4>Select Your Plan</h4>
          <p>Purchase your membership plan and get more content than others.</p>
          <div className="divider-dash"></div>
          <div className="flex-wrap-bar">
            <div className="wrap-card">
              <img src={logord} alt="" className="card-pic" />
              <h3>Silver</h3>
              <p>
                <ul>
                  <li>1 Adverticements</li>
                  <li>10% Winning Chance</li>
                  <li>1 Day Validity</li>
                  <li>1K Views</li>
                </ul>
              </p>

              <div className="flex-bar">
                {localStorage.getItem("userPlan") === "silver" ? (
                  <div className="button-box green">
                    <CheckCircleOutlineIcon fontSize="small" />
                    <div className="btn-text">Activated</div>
                  </div>
                ) : (
                  <div className="button-box">
                    <ShoppingCartIcon fontSize="small" />
                    <div className="btn-text">$ 4.99/=</div>
                  </div>
                )}
              </div>
            </div>
            <div className="wrap-card">
              <img src={logord} alt="" className="card-pic" />
              <h3>Gold</h3>
              <p>
                <ul>
                  <li>10 Adverticements</li>
                  <li>55% Winning Chance</li>
                  <li>1 Month Validity</li>
                  <li>50K Views</li>
                </ul>
              </p>

              <div className="flex-bar">
                {localStorage.getItem("userPlan") === "advance" ? (
                  <div className="button-box green">
                    <CheckCircleOutlineIcon fontSize="small" />
                    <div className="btn-text">Activated</div>
                  </div>
                ) : (
                  <div className="button-box">
                    <ShoppingCartIcon fontSize="small" />
                    <div className="btn-text">$ 9.99/=</div>
                  </div>
                )}
              </div>
            </div>
            <div className="wrap-card">
              <img src={logord} alt="" className="card-pic" />
              <h3>Platinum</h3>
              <p>
                <ul>
                  <li>150 Adverticements</li>
                  <li>72% Winning Chance</li>
                  <li>1 Year Validity</li>
                  <li>1M Views</li>
                </ul>
              </p>
              <div className="flex-bar">
                {localStorage.getItem("userPlan") === "professional" ? (
                  <div className="button-box green">
                    <CheckCircleOutlineIcon fontSize="small" />
                    <div className="btn-text">Activated</div>
                  </div>
                ) : (
                  <div className="button-box">
                    <ShoppingCartIcon fontSize="small" />
                    <div className="btn-text">$ 99.99/=</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="view">
          <h1>Subscribe to Dspace</h1>
          <p>
            {showEmailbar
              ? `Please enter your correct email address for subscribe.`
              : `Keep in touch with us by clicking on subscribe button.`}
          </p>
          {showEmailbar ? (
            <div className="input-bar">
              <div className="icon">
                <AlternateEmailIcon fontSize="small" />
              </div>
              <input
                type="email"
                required
                name="email"
                placeholder="Email"
                id="email"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                    if (e.target.value.match(mailformat)) {
                      setsubsEmail(e.target.value);
                    } else {
                      setsubsEmail("");
                    }
                  }
                }}
              />
            </div>
          ) : null}
          <div className="flex-bar">
            {localStorage.getItem("userSubscription") === "no" ? (
              <div
                className="button-box"
                onClick={() => {
                  if (showEmailbar) {
                    subscribeNow();
                  } else {
                    setshowEmailbar(true);
                  }
                }}
              >
                <NewspaperIcon fontSize="small" />
                <div className="btn-text">Subscribe</div>
              </div>
            ) : (
              <div
                className="button-box"
                onClick={() => {
                  unsubscribeNow();
                }}
              >
                <NewspaperIcon fontSize="small" />
                <div className="btn-text">Unsubscribe</div>
              </div>
            )}
          </div>
        </div>
        <div className="view">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
